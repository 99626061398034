"use strict";

var _interopRequireDefault = require("/home/enotefront/app/snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aloneStore = aloneStore;
exports.appUpload = appUpload;
exports.changeOn = changeOn;
exports.changeStore = changeStore;
exports.dataManipulation = dataManipulation;
exports.deleList = deleList;
exports.getLists = getLists;
exports.getTaskversion = getTaskversion;
exports.searchApp = searchApp;
exports.searchStatus = searchStatus;
exports.searchVersions = searchVersions;

var _request = _interopRequireDefault(require("@/utils/request"));

//查询Android应用商店应用信息
function getLists(data) {
  return (0, _request.default)({
    url: 'system/business/android/store/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //删除应用商店


function deleList(id) {
  return (0, _request.default)({
    url: 'system/business/android/store/delete/' + id,
    method: 'delete',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
} //上下线应用商店


function changeOn(data) {
  return (0, _request.default)({
    url: 'system/business/android/store/line',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //查询应用


function searchStatus(data) {
  return (0, _request.default)({
    url: 'system/business/android/store/name',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //上传


function appUpload(data) {
  return (0, _request.default)({
    url: 'system/business/android/store/upload',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 1200000
  });
} //app上传检查


function searchApp(data) {
  return (0, _request.default)({
    url: 'system/business/android/store/upload/check',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 600000
  });
} //根据应用查询版本


function searchVersions(data) {
  return (0, _request.default)({
    url: 'system/business/android/store/version',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //查询固件版本号


function getTaskversion() {
  return (0, _request.default)({
    url: 'system/business/android/task/version',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
} //根据id查询应用商店


function aloneStore(id) {
  return (0, _request.default)({
    url: 'system/business/android/store/by/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
} //修改应用商店


function changeStore(data) {
  return (0, _request.default)({
    url: 'system/business//android/store/update',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
/**
 * 前端刷新公共方法
 */


function dataManipulation(tableData, rowData, fixPoint, opTime) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == rowData.id) {
      if (name != null && name != "") {
        element.name = name;
      }

      if (fixPoint != null && fixPoint != "") {
        element.fixPoint = fixPoint;
      }

      element.opUser = window.localStorage.getItem("username");

      if (opTime != null && opTime != "") {
        element.opTime = opTime;
      }
    }
  });
  return tableData;
}