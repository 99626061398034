"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _login = require("../../api/login.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      options: [{
        value: "1",
        label: "手机"
      }, {
        value: "2",
        label: "邮箱"
      }, {
        value: "3",
        label: "微信"
      }],
      option: [{
        value: "2",
        label: "手机APP"
      }, {
        value: "3",
        label: "终端设备"
      }, {
        value: "1",
        label: "网页云盘"
      }, {
        value: "4",
        label: "用户平台"
      }],
      tableData: [],
      loading: false,
      form: {
        createTimeEnd: "",
        createTimeStart: "",
        telephone: "",
        email: "",
        equipment: "",
        loginMethod: "",
        pageNo: 1,
        pageSize: 10,
        userName: ""
      },
      total: 0,
      currentPage: 1
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        (0, _login.getLogin)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
        });
      }, 500);
    },
    //信息提示框
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //登录统计导出
    exportLoginRecord: function exportLoginRecord() {
      var _this2 = this;

      if (this.form.createTimeStart == undefined || this.form.createTimeStart.length < 1 || this.form.createTimeStart == '' || this.form.createTimeStart == null) {
        this.msgTip("error", "请选择起始日期！");
        return;
      }

      if (this.form.createTimeEnd == undefined || this.form.createTimeEnd.length < 1 || this.form.createTimeEnd == '' || this.form.createTimeEnd == null) {
        this.msgTip("error", "请选择截止日期！");
        return;
      }

      this.loading = true;
      (0, _login.exportRecord)({
        startTime: this.form.createTimeStart,
        endTime: this.form.createTimeEnd
      }).then(function (res) {
        var data = new Blob([res], {
          type: "application/.xls"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "登录设备统计报表.xls";
        anchor.click();
        window.URL.revokeObjectURL(data);
        _this2.loading = false;
      });
    },
    //查询
    search: function search() {
      var _this3 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _login.getLogin)({
          createTimeEnd: _this3.form.createTimeEnd,
          createTimeStart: _this3.form.createTimeStart,
          equipment: _this3.form.equipment,
          loginMethod: _this3.form.loginMethod,
          telephone: _this3.form.telephone,
          email: _this3.form.email,
          pageNo: 1,
          pageSize: 10,
          userName: ""
        }).then(function (res) {
          _this3.tableData = res.voList;
          _this3.total = Number(res.total);
          _this3.currentPage = 1;
          _this3.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.createTimeEnd = "";
      this.form.createTimeStart = "";
      this.form.equipment = "";
      this.form.loginMethod = "";
      this.form.userName = "";
      this.form.telephone = "";
      this.form.email = "";
      this.getList();
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
exports.default = _default;