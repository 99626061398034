"use strict";

var _interopRequireDefault = require("/home/enotefront/app/snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.amendDislist = amendDislist;
exports.amendsDislist = amendsDislist;
exports.dataManipulation = dataManipulation;
exports.executeTime = executeTime;
exports.getDislist = getDislist;
exports.searchDislist = searchDislist;
exports.startDislist = startDislist;

var _request = _interopRequireDefault(require("@/utils/request"));

//调度任务信息列表
function getDislist(data) {
  return (0, _request.default)({
    url: 'system/base/schedule',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //查询查询日志


function searchDislist(data) {
  return (0, _request.default)({
    url: 'system/base/schedule/param',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //启用定时任务


function startDislist(data) {
  return (0, _request.default)({
    url: 'system/base/schedule/open/close',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} // //停用定时任务
// export function endDislist() {
//     return request({
//         url: 'system/base/schedule/close',
//         method: 'put'
//     })
// }
//修改调度任务信息


function amendDislist(data) {
  return (0, _request.default)({
    url: 'system/base/schedule',
    method: 'put',
    data: data,
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
} //通过id查询任务信息


function amendsDislist(id) {
  return (0, _request.default)({
    url: 'system/base/schedule/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
} //执行定时任务


function executeTime(id) {
  return (0, _request.default)({
    url: 'system/base/schedule/execute/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
/**
 * 前端刷新公共方法
 */


function dataManipulation(tableData, rowData, status) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    rowData.map(function (row) {
      if (element.id == row.id) {
        if (status != null && status != "") {
          element.status = status;
        }
      }
    });
  });
  return tableData;
}