"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _modelequment = require("../../api/modelequment.js");

var _firmwarepair = require("../../api/firmwarepair.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isDisable: false,
      value: "",
      loading: false,
      tableData: [],
      form: {
        equipmentModel: "",
        pageNo: 1,
        pageSize: 10,
        version: ""
      },
      currentPage: 1,
      total: 0,
      option: []
    };
  },
  created: function created() {
    var _this = this;

    this.getList();
    (0, _firmwarepair.getLanList)({
      name: "EQUIPMENT_MODEL",
      value: ""
    }).then(function (res) {
      _this.option = res.dictionaryVOList;
    });
  },
  methods: {
    getList: function getList() {
      var _this2 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _modelequment.getModellist)(_this2.form).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.loading = false;
          _this2.isRepeat = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    //查询
    search: function search() {
      var _this3 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _modelequment.getModellist)({
          equipmentModel: _this3.form.equipmentModel,
          pageNo: 1,
          pageSize: 10,
          version: _this3.form.version
        }).then(function (res) {
          _this3.tableData = res.voList;
          _this3.total = res.total;
          _this3.currentPage = 1;
          _this3.loading = false;
          _this3.isRepeat = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.equipmentModel = "";
      this.form.version = "";
      this.currentPage = 1;
      this.getList();
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    }
  }
};
exports.default = _default;