"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.set");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _systems = require("@/api/systems");

var _systemuser = require("@/api/systemuser");

var _validate = require("@/utils/validate");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var validatePassword = function validatePassword(rule, value, callback) {
      if (!/^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{8,20}$/.test(value)) {
        callback(new Error("请设置8-20位含数字、字母、符号的密码"));
      } else {
        callback();
      }
    };

    var validateUser = function validateUser(rule, value, callback) {
      if (!/^[0-9a-zA-Z]+$/.test(value)) {
        callback(new Error("用户名只能为英文/数字"));
      } else {
        callback();
      }
    };

    return {
      roleList: [],
      isDisable: false,
      isFlag: true,
      form: {
        username: "",
        name: "",
        status: "",
        startDate: "",
        endDate: "",
        roleId: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: ""
      },
      //修改用户
      exchange: {
        username: "",
        name: "",
        phone: "",
        email: ""
      },
      exchangeRules: {
        name: [{
          required: true,
          trigger: "blur"
        }]
      },
      //添加用户
      addform: {
        email: "",
        name: "",
        password: "",
        phone: "",
        username: "",
        roleId: ""
      },
      addRules: {
        username: [{
          required: true,
          trigger: "blur",
          validator: validateUser
        }],
        password: [{
          required: true,
          trigger: "blur",
          validator: validatePassword
        }],
        name: [{
          required: true,
          message: "请输入姓名",
          trigger: "blur"
        }]
      },
      //修改密码
      pasds: {
        usernames: "",
        password: ""
      },
      pasdRules: {
        password: [{
          required: true,
          trigger: "blur",
          validator: validatePassword
        }]
      },
      changeid: "",
      dialogFormVisible: false,
      dialogVisible: false,
      dialogFormsVisible: false,
      dialogVisibles: false,
      loading: false,
      isRepeat: false,
      options: [],
      option: [{
        value: 0,
        label: "锁定"
      }, {
        value: 1,
        label: "正常"
      }, {
        value: 2,
        label: "停用"
      }],
      tableData: [],
      formLabelWidth: "120px",
      multipleSelection: [],
      authname: [],
      //授权用户名
      authvalue: [],
      //授权所属角色
      authors: [],
      //所属角色数组
      authorid: [],
      keepid: "",
      userIdList: [],
      roleldList: [],
      total: 0,
      currentPage: 1,
      user: []
    };
  },
  created: function created() {
    var _this = this;

    this.getList();
    (0, _systems.getAlluser)().then(function (res) {
      _this.options = res.listVO;
    });
  },
  methods: {
    getList: function getList() {
      var _this2 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _systemuser.getUsermess)(_this2.form).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.loading = false;
          _this2.isRepeat = false;
        });
      }, 500);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    stateFormat: function stateFormat(row) {
      if (row.status === "0") {
        return "锁定";
      } else if (row.status === "1") {
        return "正常";
      } else {
        return "停用";
      }
    },
    close: function close() {
      this.$refs.addform.resetFields();
      this.authors = [];
      this.dialogVisible = false;
      this.addform.phone = "";
      this.addform.email = "";
      this.dialogFormsVisible = false;
    },
    pasdclose: function pasdclose() {
      this.$refs.pasds.resetFields();
      this.dialogFormVisible = false;
    },
    exchangeClose: function exchangeClose() {
      this.$refs.exchange.resetFields();
      this.dialogVisibles = false;
      this.exchange.phone = "";
      this.exchange.email = "";
    },
    // 启用
    enableItem: function enableItem() {
      var _this3 = this;

      if (!this.findSelectData()) {
        this.msgTip("error", "至少选择一条数据");
      } else {
        if (this.isFlag) {
          this.isFlag = false;
          var arr = [];
          this.multipleSelection.map(function (res) {
            arr.push(JSON.stringify(res.id));
          });
          this.isRepeat = true;
          (0, _systemuser.startUsermess)({
            idList: arr
          }).then(function (res) {
            if (res.success) {
              _this3.$message({
                type: "success",
                message: "批量启用成功",
                center: true
              }); // 前端刷新


              (0, _systemuser.dataManipulation)(_this3.tableData, _this3.multipleSelection, "", "", "", window.localStorage.getItem("username"), getDate(), "1");
              _this3.isFlag = true;
            } else {
              _this3.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });

              _this3.isRepeat = false;
              _this3.isFlag = true;
            }
          });
        }
      }
    },
    // 停用
    disableItem: function disableItem() {
      var _this4 = this;

      if (!this.findSelectData()) {
        this.msgTip("error", "至少选择一条数据");
      } else {
        if (this.isFlag) {
          this.isFlag = false;
          var arr = [];
          this.multipleSelection.map(function (res) {
            arr.push(res.id);
          });
          this.isRepeat = true;
          (0, _systemuser.closeUsermess)({
            idList: arr
          }).then(function (res) {
            if (res.success) {
              _this4.$message({
                type: "success",
                message: "批量停用成功",
                center: true
              }); // 前端刷新


              (0, _systemuser.dataManipulation)(_this4.tableData, _this4.multipleSelection, "", "", "", window.localStorage.getItem("username"), getDate(), "2");
              _this4.isFlag = true;
            } else {
              _this4.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });

              _this4.isRepeat = false;
              _this4.isFlag = true;
            }
          });
        }
      }
    },
    // 查找有没有选中数据，没有则返回false
    findSelectData: function findSelectData() {
      if (!this.multipleSelection.length) {
        // 无选中的数据
        return false;
      } else {
        // 有选中的数据
        return true;
      }
    },
    //信息提示框
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //查询
    search: function search() {
      var _this5 = this;

      this.loading = true;
      setTimeout(function () {
        if (_this5.form.startDate == null) {
          _this5.form.startDate = "";
        }

        if (_this5.form.endDate == null) {
          _this5.form.endDate = "";
        }

        (0, _systemuser.getUsermess)({
          username: _this5.form.username,
          roleId: _this5.form.name,
          status: _this5.form.status,
          startDate: _this5.form.startDate,
          endDate: _this5.form.endDate,
          pageNo: 1,
          pageSize: 10,
          sortField: "",
          sortRules: ""
        }).then(function (res) {
          _this5.form.pageNo = 1;
          _this5.tableData = res.voList;
          _this5.currentPage = 1;
          _this5.loading = false;
          _this5.total = res.total;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.username = "";
      this.form.name = "";
      this.form.status = "";
      this.form.startDate = "";
      this.form.endDate = "";
      this.form.pageNo = 1;
      this.getList();
    },
    //批量授权
    getauth: function getauth() {
      var _this6 = this;

      if (!this.findSelectData()) {
        this.msgTip("error", "至少选择一条数据");
      } else {
        this.dialogFormsVisible = true;
        this.isDisable = false;
        var arr = [];
        this.authname = [];
        this.authvalue = [];
        this.multipleSelection.map(function (res) {
          arr.push(res.id);

          _this6.authname.push(res.username);

          res.roleVOList.map(function (item) {
            _this6.authvalue = item.id.split(",");
          });
          _this6.userIdList = _this6.authvalue;
        });
        this.authvalue = Array.from(new Set(this.authvalue));
        this.userIdList = this.authvalue;
        this.authorid = arr; // getCheckuser(arr).then(res => {
        //   this.authname.push(res.username);
        // });

        (0, _systems.getAlluser)().then(function (res) {
          _this6.authors = res.listVO;
        });
      }
    },
    //修改用户
    amend: function amend(v) {
      var _this7 = this;

      this.isDisable = false;
      this.dialogVisibles = true;
      (0, _systemuser.getIduser)(v.id).then(function (res) {
        _this7.exchange.username = res.username;
        _this7.exchange.name = res.name;
        _this7.exchange.phone = res.phone;
        _this7.exchange.email = res.email;
      });
      this.keepid = v.id;
    },
    keep: function keep() {
      var _this8 = this;

      if (this.exchange.phone != "") {
        if (!/^1\d{10}$/.test(this.exchange.phone)) {
          this.msgTip("warning", "请输入正确的手机号");
          return;
        }
      }

      if (this.exchange.email != "") {
        if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(this.exchange.email)) {
          this.msgTip("warning", "请输入正常的邮箱");
          return;
        }
      }

      this.$refs.exchange.validate(function (valid) {
        if (valid) {
          _this8.isRepeat = true;
          _this8.isDisable = true;
          (0, _systemuser.amendUsermess)(_this8.keepid, _this8.exchange).then(function (res) {
            if (res.success) {
              _this8.$message({
                type: "success",
                message: "修改成功",
                center: true
              });

              _this8.dialogVisibles = false;

              _this8.getList();
            } else {
              _this8.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });

              _this8.isRepeat = false;
              _this8.isDisable = true;
            }
          });
        } else {
          _this8.msgTip("warning", "请填写姓名");

          return false;
        }
      });
    },
    //解锁
    unlock: function unlock(v) {
      var _this9 = this;

      (0, _systemuser.unblockUser)(v.id).then(function (res) {
        if (res.success) {
          _this9.isRepeat = true;
          (0, _systemuser.unblockUsermess)(v.id).then(function (res) {
            if (res.success) {
              _this9.$message({
                type: "success",
                message: "解锁成功",
                center: true
              });

              _this9.form.status = "";

              _this9.getList();
            } else {
              _this9.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });

              _this9.isRepeat = false;
            }
          });
        } else {
          _this9.$message({
            type: "error",
            message: res.errorMsg,
            center: true
          });
        }
      });
    },
    //授权
    accredit: function accredit(v) {
      var _this10 = this;

      this.isDisable = false;
      this.authorid = [];
      this.dialogFormsVisible = true;
      (0, _systemuser.getCheckuser)(v.id).then(function (res) {
        _this10.authname = res.username;
        var arr = [];
        var obj = [];
        res.roleVOList.map(function (res) {
          obj.push(res.id);
        });
        _this10.authvalue = obj;
        _this10.userIdList = obj;
      }); //获取所有用户权限

      (0, _systems.getAlluser)().then(function (res) {
        _this10.authors = res.listVO;
      });
      this.authorid.push(v.id);
    },
    //查找id
    pickid: function pickid(names) {
      this.userIdList = names; // console.log(names,'----')
    },
    //添加用户
    add: function add() {
      var _this11 = this;

      this.isDisable = false;
      this.dialogVisible = true;
      this.addform.username = "";
      this.addform.password = "";
      this.addform.roleId = "";
      this.authors = [];
      (0, _systems.queryRole)().then(function (res) {
        _this11.roleList = res.voList;
      });
      (0, _systems.getSubRole)().then(function (res) {
        _this11.user = res.voList;
      });
    },
    save: function save() {
      var _this12 = this;

      if (this.addform.phone != "") {
        if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.addform.phone)) {
          this.msgTip("warning", "请输入正常的手机号");
          return;
        }
      }

      if (this.addform.email != "") {
        if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(this.addform.email)) {
          this.msgTip("warning", "请输入正常的邮箱");
          return;
        }
      }

      if (this.addform.roleId == "") {
        this.msgTip("warning", "请输入所属角色");
        return;
      }

      if (this.authors.length == 0) {
        this.msgTip("warning", "请选择权限");
      } else {
        this.$refs.addform.validate(function (valid) {
          if (valid) {
            _this12.isRepeat = true;
            _this12.isDisable = true;
            (0, _systemuser.addUsermess)({
              email: _this12.addform.email,
              name: _this12.addform.name,
              password: _this12.addform.password,
              phone: _this12.addform.phone,
              username: _this12.addform.username,
              roleId: _this12.addform.roleId,
              idList: _this12.authors
            }).then(function (res) {
              if (res.success) {
                _this12.msgTip("success", "添加成功");

                _this12.dialogVisible = false;

                _this12.empty();
              } else {
                _this12.msgTip("error", res.errorMsg);

                _this12.isRepeat = false;
                _this12.isDisable = false;
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    author: function author() {
      var _this13 = this;

      if (this.authvalue == null || this.authvalue == "") {
        this.$message({
          type: "warning",
          message: "一个用户至少有一个权限",
          center: true
        });
        return;
      }

      this.roleldList = this.authorid;
      this.isRepeat = true;
      this.isDisable = true;
      (0, _systemuser.authorUsermess)({
        roleIdList: this.userIdList,
        userIdList: this.roleldList
      }).then(function (res) {
        if (res.success) {
          _this13.$message({
            type: "success",
            message: "授权成功",
            center: true
          });

          _this13.dialogFormsVisible = false;

          _this13.getList();
        } else {
          _this13.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });

          _this13.isRepeat = false;
          _this13.isDisable = false;
        }
      });
    },
    //删除
    dele: function dele(v) {
      var _this14 = this;

      this.$confirm("您是否要删除当前选中的记录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this14.isRepeat = true;
        (0, _systemuser.deleteUsermess)(v.id).then(function (res) {
          if (res.success) {
            _this14.$message({
              type: "success",
              message: "删除成功",
              center: true
            });

            _this14.getList();
          } else {
            _this14.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });

            _this14.isRepeat = false;
          }
        });
      });
    },
    //修改密码
    pasd: function pasd(v) {
      var _this15 = this;

      this.isDisable = false;
      this.dialogFormVisible = true;
      (0, _systemuser.getIduser)(v.id).then(function (res) {
        _this15.pasds.usernames = res.username;
        _this15.changeid = res.id;
      });
    },
    sure: function sure() {
      var _this16 = this;

      this.$refs.pasds.validate(function (valid) {
        if (valid) {
          _this16.isRepeat = true;
          _this16.isDisable = true;
          (0, _systemuser.pasdUsermess)({
            id: _this16.changeid,
            password: _this16.pasds.password
          }).then(function (res) {
            if (res.success) {
              _this16.msgTip("success", "修改成功");

              _this16.dialogFormVisible = false;
            } else {
              _this16.$message({
                type: "success",
                message: res.errorMsg,
                center: true
              });

              _this16.isRepeat = false;
              _this16.isDisable = false;
            }
          });
        } else {
          _this16.msgTip("warning", "请填写完整");

          return false;
        }
      });
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
/**
 * 获取当前时间
 */

exports.default = _default;

function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)

  var minutes = date.getMinutes(); //获取当前分钟数(0-59)

  var seconds = date.getSeconds();

  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }

  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }

  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}