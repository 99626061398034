"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _systems = require("../../api/systems.js");

var _dictionary = require("../../api/dictionary.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  inject: ["reload"],
  data: function data() {
    return {
      systemType: [],
      isDisable: false,
      roleDate: [],
      //列表数据
      form: {
        systemId: "",
        name: "",
        pid: "",
        pname: "",
        remark: "",
        resourceTypeId: "",
        seq: "",
        url: ""
      },
      formLabelWidth: "100px",
      dialogVisibles: false,
      loading: false,
      isRepeat: false,
      num: 1,
      type: [],
      routesData: [],
      defaultProps: {
        children: "children",
        label: "label"
      },
      addRules: {
        name: [{
          required: true,
          trigger: "blur"
        }],
        resourceTypeId: [{
          required: true,
          trigger: "blur"
        }],
        pid: [{
          required: true,
          trigger: "change"
        }]
      },
      foldItem: "",
      //修改
      dialogVisible: false,
      rowid: "",
      myid: "",
      flag: false,
      systemId: ''
    };
  },
  created: function created() {
    this.getLists();
  },
  methods: {
    getLists: function getLists() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        (0, _systems.getAllList)().then(function (res) {
          res.map(function (item) {
            if (_this.flag) {
              item.pick_up = true;
            } else {
              item.pick_up = false;
            }

            item.children.map(function (childItem) {
              childItem.pick_up = true;
              _this.loading = false;
              _this.isRepeat = false;
              return childItem;
            });
            _this.loading = false;
            _this.isRepeat = false;
            return item;
          });
          _this.roleDate = res;
        });
      }, 500);
    },
    // icon的点击child的展开及收起情况
    pickUp: function pickUp(item) {
      item.pick_up = !item.pick_up;
      this.foldItem = item.pick_up;
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //添加
    handleChange: function handleChange(value) {},
    add: function add() {
      var _this2 = this;

      this.isDisable = false;
      this.dialogVisibles = true;
      this.form.name = "";
      this.form.url = "";
      this.form.pid = "";
      this.form.resourceTypeId = "";
      this.form.seq = "";
      this.form.remark = "";
      this.form.systemId = "";
      (0, _dictionary.typeDiclist)().then(function (res) {
        _this2.type = res;
      }); // queryDictionaryType({ name: "SYSTEM_ID" }).then(res => {
      //   this.systemType = res.dictionaryVOList;
      // });

      (0, _systems.getResourcePid)().then(function (res) {
        _this2.routesData = res.map(function (item) {
          item.children.map(function (res) {
            res.children.map(function (response) {
              if (response.children) {
                delete response.children;
              }
            });
          });
          item.label = item.label;
          return item;
        });
      });
    },
    handleNodeClick: function handleNodeClick(data) {
      this.form.pid = data.label;
      this.myid = data.id;
      this.systemId = data.systemId;
    },
    save: function save() {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this3.isRepeat = true;
          _this3.isDisable = true;
          (0, _systems.addList)({
            name: _this3.form.name,
            pid: _this3.myid,
            remark: _this3.form.remark,
            resourceTypeId: _this3.form.resourceTypeId,
            seq: _this3.form.seq,
            url: _this3.form.url,
            systemId: _this3.systemId
          }).then(function (res) {
            if (res.success) {
              _this3.msgTip("success", "添加成功");

              _this3.dialogVisibles = false;

              _this3.reload(); // this.getLists();

            } else {
              _this3.msgTip("error", res.errorMsg);

              _this3.isRepeat = false;
              _this3.isDisable = false;
            }
          });
        } else {
          _this3.msgTip("warning", "请填写完整");

          return false;
        }
      });
    },
    close: function close() {
      this.form.name = "";
      this.form.url = "";
      this.form.resourceTypeId = "";
      this.form.pid = "";
      this.form.remark = "";
      this.dialogVisibles = false;
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
    //修改
    exchange: function exchange(val) {
      var _this4 = this;

      this.isDisable = false;
      this.dialogVisible = true;
      (0, _systems.idList)(val).then(function (res) {
        _this4.form.name = res.name;
        _this4.form.pid = res.pname;
        _this4.form.pname = res.pid;
        _this4.myid = res.pid;
        _this4.form.remark = res.remark;
        _this4.form.seq = res.seq;
        _this4.form.url = res.url;
        _this4.form.resourceTypeId = res.tresourcetypeId;
        _this4.form.systemId = res.systemId;
        console.log(res.systemId, '123123');
        _this4.systemId = res.systemId;

        if (res.pid == "0") {
          _this4.form.pid = 'Supernote管理系统';
        } else if (res.pid == "1") {
          _this4.form.pid = 'CMS管理系统';
        }
      });
      (0, _dictionary.typeDiclist)().then(function (res) {
        _this4.type = res;
      });
      (0, _dictionary.queryDictionaryType)({
        name: "SYSTEM_ID"
      }).then(function (res) {
        _this4.systemType = res.dictionaryVOList;
      });
      (0, _systems.getResourcePid)().then(function (res) {
        _this4.routesData = res.map(function (item) {
          item.children.map(function (res) {
            res.children.map(function (response) {
              if (response.children) {
                delete response.children;
              }
            });
          });
          item.label = item.label;
          return item;
        });
      });
      this.rowid = val;
    },
    keep: function keep() {
      var _this5 = this;

      this.isDisable = false; // let myid = null;
      // if (this.myid == null || this.myid == "") {
      //   myid = this.form.pname;
      // } else {
      //   myid = this.myid;
      // }

      if (this.form.systemId == "Supernote管理系统") {
        this.form.systemId = "0";
      } else if (this.form.systemId == "CMS管理系统") {
        this.form.systemId = "1";
      }

      if (this.form.pid == "Supernote管理系统") {
        this.systemId = "0";
      } else if (this.form.pid == "CMS管理系统") {
        this.systemId = "1";
      }

      console.log(this.systemId, '匹配');

      if (this.systemId !== this.form.systemId) {
        this.msgTip("warning", "资源与系统不匹配");
        this.isDisable = false;
        return;
      } else {
        this.isDisable = true;
      }

      this.isRepeat = true;
      (0, _systems.amendList)(this.rowid, {
        name: this.form.name,
        pid: this.myid,
        remark: this.form.remark,
        resourceTypeId: this.form.resourceTypeId,
        seq: this.form.seq,
        url: this.form.url,
        systemId: this.form.systemId
      }).then(function (res) {
        if (res.success) {
          _this5.msgTip("success", "修改成功！");

          _this5.dialogVisible = false;

          _this5.reload();
        } else {
          _this5.msgTip("error", res.errorMsg);

          _this5.isRepeat = false;
          _this5.isDisable = false;
        }
      });
    },
    dele: function dele(val) {
      var _this6 = this;

      this.$confirm("您是否要删除当前选中的记录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this6.isRepeat = true;
        (0, _systems.deleteList)(val).then(function (res) {
          if (res.success) {
            _this6.$message({
              type: "success",
              message: "删除成功",
              center: true
            });

            _this6.reload(); // this.getLists();

          } else {
            _this6.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });

            _this6.isRepeat = false;
          }
        });
      });
    },
    //折叠
    fold: function fold() {
      var fold = document.getElementById("fold");

      if (fold.innerText == "折叠") {
        this.flag = false;
        document.getElementById("fold").innerText = "展开";
      } else if (fold.innerText == "展开") {
        this.flag = true;
        document.getElementById("fold").innerText = "折叠";
      }

      this.getLists();
    },
    //刷新
    reloads: function reloads() {
      document.getElementById("fold").innerText = "展开";
      this.flag = false;
      this.getLists();
    }
  }
};
exports.default = _default;