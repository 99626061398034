"use strict";

var _interopRequireWildcard = require("/home/enotefront/app/snote-manage/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var Quill = _interopRequireWildcard(require("quill"));

var _applicationState = require("../../api/applicationState.js");

var _fs = require("fs");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { quillEditor } from "vue-quill-editor"; //调用编辑器
var _default = {
  // components: { quillEditor },
  data: function data() {
    return {
      isDisable: false,
      value: "",
      value1: "",
      value2: "",
      show: "",
      id: "",
      currentPage: 1,
      total: 0,
      tableData: [],
      lanData: [],
      appNameData: [],
      dialogFormVisible: false,
      dialogVisible: false,
      formLabelWidth: "100px",
      loading: false,
      isRepeat: false,
      form: {
        pageNo: 1,
        pageSize: 10,
        name: "",
        version: ""
      },
      data: {
        appName: "",
        appVersion: "",
        fixPoint: "",
        lan: ""
      },
      updateData: {
        appName: "",
        appVersion: "",
        fixPoint: "",
        lan: ""
      },
      updateRowData: {
        appName: "",
        appVersion: "",
        fixPoint: "",
        lan: ""
      },
      rulesadd: {
        lan: [{
          required: true,
          trigger: "change"
        }],
        appName: [{
          required: true,
          trigger: "change"
        }],
        appVersion: [{
          required: true,
          trigger: "change"
        }],
        fixPoint: [{
          required: true,
          trigger: "blur"
        }]
      },
      editorOption: {
        placeholder: "请输入文本信息...",
        theme: "snow"
      },
      addRange: [],
      Rangeflag: false
    };
  },
  created: function created() {
    this.getLists();
  },
  mounted: function mounted() {},
  methods: {
    getLists: function getLists() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        (0, _applicationState.getList)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    //删除
    dele: function dele(id) {
      var _this2 = this;

      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _applicationState.deleFixpoint)(id).then(function (res) {
          if (res.success) {
            _this2.$message({
              type: "success",
              message: "删除成功",
              center: true
            });

            _this2.getLists();
          } else {
            _this2.$message({
              type: "error",
              message: res.errorMsg,
              center: true
            });
          }
        });
      });
    },
    //添加
    add: function add() {
      this.$router.push({
        path: "addapplicationState"
      });
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "changeapplicationState",
        query: {
          id: id
        }
      });
    },
    //查询
    search: function search() {
      var _this3 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _applicationState.getList)({
          pageNo: 1,
          pageSize: 10,
          name: _this3.form.name,
          version: _this3.form.version
        }).then(function (res) {
          _this3.tableData = res.voList;
          _this3.total = res.total;
          _this3.loading = false;
          _this3.currentPage = 1;
          _this3.isRepeat = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.name = "";
      this.form.version = "";
      this.currentPage = 1;
      this.form.pageNo = 1;
      this.getLists();
    },
    //获取页码
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getLists();
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
/**
 * 获取当前时间
 */

exports.default = _default;

function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)

  var minutes = date.getMinutes(); //获取当前分钟数(0-59)

  var seconds = date.getSeconds();

  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }

  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }

  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}