"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _storerecord = require("../../api/storerecord.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isDisable: false,
      value: "",
      loading: false,
      tableData: [],
      form: {
        logicVersion: "",
        packageName: "",
        equipmentNumber: "",
        pageNo: 1,
        pageSize: 10,
        type: ""
      },
      types: [{
        value: "1",
        label: "安装"
      }, {
        value: "2",
        label: "卸载"
      }],
      currentPage: 1,
      total: 0
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        (0, _storerecord.getrecordList)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    //查询
    search: function search() {
      var _this2 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _storerecord.getrecordList)({
          logicVersion: _this2.form.logicVersion,
          packageName: _this2.form.packageName,
          equipmentNumber: _this2.form.equipmentNumber,
          name: _this2.form.name,
          version: _this2.form.version,
          pageNo: 1,
          pageSize: 10,
          type: _this2.form.type
        }).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.currentPage = 1;
          _this2.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.equipmentNumber = "";
      this.form.name = "";
      this.form.version = "";
      this.form.type = "";
      this.currentPage = 1;
      this.form.pageNo = 1;
      this.getList();
    },
    //判断类型
    equimentType: function equimentType(row) {
      if (row.type == "1") {
        return '安装';
      } else {
        return '卸载';
      }
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
exports.default = _default;