"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _equimentVersion = require("../../api/equimentVersion.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isDisable: false,
      value: "",
      loading: false,
      tableData: [],
      form: {
        logicVersion: "",
        packageName: "",
        equipmentNumber: "",
        pageNo: 1,
        pageSize: 10,
        type: "",
        updateType: ''
      },
      types: [{
        value: "1",
        label: "系统"
      }, {
        value: "2",
        label: "应用"
      }],
      updateTypes: [{
        value: "1",
        label: "在线"
      }, {
        value: "2",
        label: "U盘"
      }],
      currentPage: 1,
      total: 0
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        (0, _equimentVersion.getequimentList)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    //查询
    search: function search() {
      var _this2 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _equimentVersion.getequimentList)({
          logicVersion: _this2.form.logicVersion,
          packageName: _this2.form.packageName,
          equipmentNumber: _this2.form.equipmentNumber,
          pageNo: 1,
          pageSize: 10,
          type: _this2.form.type,
          updateType: _this2.form.updateType
        }).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.currentPage = 1;
          _this2.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.equipmentNumber = "";
      this.form.logicVersion = "";
      this.form.packageName = "";
      this.form.updateType = "";
      this.form.type = "";
      this.currentPage = 1;
      this.form.pageNo = 1;
      this.getList();
    },
    //判断类型
    equimentType: function equimentType(row) {
      if (row.type == "1") {
        return '系统';
      } else {
        return '应用';
      }
    },
    //判断更新方式
    updateType: function updateType(row) {
      if (row.updateType == "1") {
        return '在线';
      } else if (row.updateType == "2") {
        return 'U盘';
      }
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
exports.default = _default;