"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _feedbackRecod = require("../../api/feedbackRecod.js");

var _fs = require("fs");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      value: "",
      value1: "",
      value2: "",
      typeId1: "",
      typeId2: "",
      total: 0,
      selectFirst: '',
      selectSecond: '',
      selectThird: '',
      currentPage: 0,
      loading: false,
      options: [{
        typeId: "",
        valueCn: ""
      }],
      form: {
        pageNo: 1,
        pageSize: 10,
        equipmentNumber: '',
        contact: '',
        valueOne: '',
        valueTwo: '',
        valueThree: ''
      },
      tableData: [],
      state: {}
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        (0, _feedbackRecod.getFeedbackRecodList)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.form.pageSize = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    getFirst: function getFirst() {
      var _this2 = this;

      (0, _feedbackRecod.getFeedbackRecodFirst)({
        typeId: '',
        lan: 'zh-CN'
      }).then(function (res) {
        _this2.selectSecond = "";
        _this2.selectThird = "";
        _this2.form.valueThree = "";
        _this2.form.valueTwo = "", _this2.options = res.voList;
      });
    },
    getSecond: function getSecond() {
      var _this3 = this;

      (0, _feedbackRecod.getFeedbackRecodSecond)({
        typeId: this.typeId1,
        lan: 'zh-CN'
      }).then(function (res) {
        _this3.selectThird = "";
        _this3.form.valueThree = "";
        _this3.options = res.voList;
      });
    },
    getThird: function getThird() {
      var _this4 = this;

      (0, _feedbackRecod.getFeedbackRecodThird)({
        typeId: this.typeId2,
        lan: 'zh-CN'
      }).then(function (res) {
        _this4.options = res.voList;
      });
    },
    handleChangeFirst: function handleChangeFirst(value) {
      this.typeId1 = value;
      this.form.valueOne = value;
    },
    handleChangeSecond: function handleChangeSecond(value) {
      this.typeId2 = value;
      this.form.valueTwo = value;
    },
    handleChangeThird: function handleChangeThird(value) {
      this.form.valueThree = value;
    },
    //导出
    exportAll: function exportAll() {
      (0, _feedbackRecod.exportFeedbackRecodList)(this.form).then(function (res) {
        var data = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var result = document.getElementById("result");
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "FeedbackRecordRecharge.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(data);
      });
    },
    //查询
    search: function search() {
      var _this5 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _feedbackRecod.getFeedbackRecodList)({
          pageNo: 1,
          pageSize: 10,
          equipmentNumber: _this5.form.equipmentNumber,
          contact: _this5.form.contact,
          valueOne: _this5.form.valueOne,
          valueTwo: _this5.form.valueTwo,
          valueThree: _this5.form.valueThree
        }).then(function (res) {
          _this5.tableData = res.voList;
          _this5.total = res.total;
          _this5.currentPage = 1;
          _this5.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.equipmentNumber = "";
      this.form.contact = "";
      this.form.valueOne = "";
      this.form.valueTwo = "";
      this.form.valueThree = "";
      this.selectFirst = "";
      this.selectSecond = "";
      this.selectThird = "";
      this.getList();
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
exports.default = _default;