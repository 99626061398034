"use strict";

var _interopRequireDefault = require("/home/enotefront/app/snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("/home/enotefront/app/snote-manage/node_modules/core-js/modules/es6.array.iterator.js");

require("/home/enotefront/app/snote-manage/node_modules/core-js/modules/es6.promise.js");

require("/home/enotefront/app/snote-manage/node_modules/core-js/modules/es6.object.assign.js");

require("/home/enotefront/app/snote-manage/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _vueCron = _interopRequireDefault(require("vue-cron"));

var _vueQuillEditor = _interopRequireDefault(require("vue-quill-editor"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));

require("quill/dist/quill.core.css");

require("quill/dist/quill.snow.css");

require("quill/dist/quill.bubble.css");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _directive = require("./utils/directive");

require("@/icons");

require("@/permission");

var _vueSimpleUploader = _interopRequireDefault(require("vue-simple-uploader"));

// A modern alternative to CSS resets
// lang i18n
// require styles 引入样式
// global css
// icon
// permission control
_vue.default.use(_vueSimpleUploader.default);

(0, _directive.directive)(); // import axios from 'axios'
// axios.defaults.withCredentials = true; //让ajax携带cookie
// Vue.prototype.$axios = axios;

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// import {
//     mockXHR
// } from '../mock'
// if (process.env.NODE_ENV === 'production') {
//     mockXHR()
// }
// set ElementUI lang to EN

_vue.default.use(_elementUi.default, {
  locale: _zhCN.default
});

_vue.default.use(_vueQuillEditor.default);

_vue.default.use(_vueCron.default);

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});