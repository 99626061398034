"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  computed: {}
};
exports.default = _default;