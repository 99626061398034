"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _dictionary = require("../../api/dictionary.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isDisable: false,
      value: "",
      param: {
        name: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      },
      rowid: "",
      form: {
        name: "",
        remark: "",
        value: "",
        valueCn: "",
        valueEn: ""
      },
      forms: {
        name: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      },
      dialogFormVisible: false,
      dialogVisible: false,
      tableData: [],
      formLabelWidth: "120px",
      total: 0,
      currentPage: 1,
      flag: false,
      loading: false,
      isRepeat: false,
      //添加
      addForm: {
        value: [{
          required: true,
          trigger: "blur"
        }],
        name: [{
          required: true,
          trigger: "blur"
        }],
        remark: [{
          required: true,
          trigger: "blur"
        }],
        valueCn: [{
          required: true,
          trigger: "blur"
        }],
        valueEn: [{
          required: true,
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        (0, _dictionary.searchDiclist)(_this.param).then(function (res) {
          _this.currentrow = null;
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.param.pageNo = currentPage;
      this.getList();
    },
    //查询
    search: function search() {
      var _this2 = this;

      //this.getList()
      this.loading = true;
      this.param.pageNo = 1;
      setTimeout(function () {
        (0, _dictionary.searchDiclist)(_this2.param).then(function (res) {
          if (res.success) {
            _this2.param.pageNo = 1;
            _this2.tableData = res.voList;
            _this2.total = res.total;
            _this2.currentPage = 1;
            _this2.loading = false;
          }
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      var _this3 = this;

      this.param.name = "";
      this.param.valueMeaning = "";
      this.loading = true;
      this.param.pageNo = 1;
      setTimeout(function () {
        (0, _dictionary.searchDiclist)(_this3.param).then(function (res) {
          if (res.success) {
            // this.param.pageNo = 1;
            _this3.tableData = res.voList;
            _this3.total = res.total;
            _this3.currentPage = 1;
            _this3.loading = false;
          }
        });
      }, 500);
    },
    //添加
    add: function add() {
      this.dialogFormVisible = true;
      this.isDisable = false;
    },
    sure: function sure() {
      var _this4 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this4.isRepeat = true;
          _this4.isDisable = true;
          (0, _dictionary.addDiclist)(_this4.form).then(function (res) {
            if (res.success) {
              _this4.$message({
                type: "success",
                message: "添加成功",
                center: true
              });

              _this4.dialogFormVisible = false;

              _this4.empty();
            } else {
              _this4.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });

              _this4.isRepeat = false;
              _this4.isDisable = false;
            }
          });
        } else {
          _this4.msgTip("warning", "请填写完整");

          return false;
        }
      });
    },
    //弹框关闭
    close: function close() {
      this.dialogFormVisible = false;
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
    //点击获取这行数据
    getRow: function getRow(row) {
      this.currentrow = row;
      this.rowid = row.id;
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //修改
    amend: function amend() {
      var _this5 = this;

      if (this.currentrow == null) {
        this.msgTip("error", "请选择需要修改的数据");
      } else {
        this.dialogVisible = true;
        this.isDisable = false;
        (0, _dictionary.getDiclist)(this.rowid).then(function (res) {
          _this5.form.value = res.value;
          _this5.form.name = res.name;
          _this5.form.remark = res.remark;
          _this5.form.valueCn = res.valueCn;
          _this5.form.valueEn = res.valueEn;
        });
      }
    },
    save: function save() {
      var _this6 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this6.isRepeat = true;
          _this6.isDisable = true;
          (0, _dictionary.amendDiclist)(_this6.rowid, _this6.form).then(function (res) {
            if (res.success) {
              _this6.$message({
                type: "success",
                message: "修改成功",
                center: true
              });

              _this6.dialogVisible = false;
              (0, _dictionary.dataManipulation)(_this6.tableData, _this6.currentrow, _this6.form.remark, _this6.form.valueCn, _this6.form.valueEn, window.localStorage.getItem("name"), getDate()); //this.getList();
            } else {
              _this6.$message({
                type: "success",
                message: res.errorMsg,
                center: true
              });

              _this6.isRepeat = false;
              _this6.isDisable = false;
            }
          });
        } else {
          _this6.msgTip("warning", "请填写完整");

          return false;
        }
      });
    },
    //删除
    delet: function delet() {
      var _this7 = this;

      if (this.currentrow == null) {
        this.msgTip("error", "请选择需要删除的数据！");
      } else {
        this.$confirm("您是否要删除当前选中的记录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this7.isRepeat = true;
          (0, _dictionary.deleteDiclist)(_this7.rowid).then(function (res) {
            if (res.success) {
              _this7.$message({
                type: "success",
                message: "删除成功",
                center: true
              });

              _this7.getList();
            } else {
              _this7.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });

              _this7.isRepeat = false;
            }
          });
        });
      }
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
/**
 * 获取当前时间
 */

exports.default = _default;

function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)

  var minutes = date.getMinutes(); //获取当前分钟数(0-59)

  var seconds = date.getSeconds();

  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }

  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }

  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}