"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _reference = require("../../api/reference.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isDisable: false,
      value: "",
      total: 0,
      currentPage: 1,
      form: {
        name: "",
        remark: "",
        serial: "",
        value: "",
        valueCn: ""
      },
      forms: {
        name: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: ""
      },
      tableData: [],
      dialogFormVisible: false,
      dialogVisible: false,
      formLabelWidth: "120px",
      saveid: "",
      loading: false,
      //添加
      addForm: {
        value: [{
          required: true,
          trigger: "blur"
        }],
        name: [{
          required: true,
          trigger: "blur"
        }],
        serial: [{
          required: true,
          trigger: "blur"
        }],
        valueCn: [{
          required: true,
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        (0, _reference.getreferlist)(_this.forms).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
        });
      }, 500);
    },
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    //查询
    search: function search() {
      var _this2 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _reference.getreferlist)({
          name: _this2.forms.name,
          pageNo: 1,
          pageSize: 10,
          sortField: "",
          sortRules: ""
        }).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.currentPage = 1;
          _this2.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.forms.name = "";
      this.getList();
    },
    //添加
    add: function add() {
      this.dialogFormVisible = true;
      this.isDisable = false;
    },
    sure: function sure() {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this3.isDisable = true; // 表单验证通过之后的操作

          (0, _reference.addList)(_this3.form).then(function (res) {
            if (res.success) {
              _this3.$message({
                type: "success",
                message: "添加成功",
                center: true
              });

              _this3.dialogFormVisible = false;

              _this3.getList();
            } else {
              _this3.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
            }
          });
        } else {
          _this3.msgTip("warning", "请填写完整");

          return false;
          _this3.isDisable = false;
        }
      });
    },
    //弹框关闭
    close: function close() {
      this.dialogFormVisible = false;
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
    //修改
    change: function change(v) {
      var _this4 = this;

      this.dialogVisible = true;
      this.isDisable = false;
      (0, _reference.changeList)(v.id).then(function (res) {
        _this4.form.name = res.name;
        _this4.form.serial = res.serial;
        _this4.form.value = res.value;
        _this4.form.valueCn = res.valueCn;
      });
      this.saveid = v.id;
    },
    save: function save() {
      var _this5 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this5.isDisable = true; // 表单验证通过之后的操作

          (0, _reference.updateList)(_this5.saveid, _this5.form).then(function (res) {
            if (res.success) {
              _this5.$message({
                type: "success",
                message: "修改成功",
                center: true
              });

              _this5.dialogVisible = false;

              _this5.getList();
            } else {
              _this5.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });

              _this5.isDisable = true;
            }
          });
        } else {
          _this5.msgTip("warning", "请填写完整");

          return false;
        }
      });
    },
    //删除
    dele: function dele(v) {
      var _this6 = this;

      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _reference.deletaList)(v.id).then(function (res) {
          if (res.success) {
            _this6.$message({
              type: "success",
              message: "删除成功",
              center: true
            });

            _this6.getList();
          } else {
            _this6.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
          }
        });
      });
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
exports.default = _default;