"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.split");

var _equipment = require("../../api/equipment.js");

var _dictionary = require("../../api/dictionary.js");

var _dealer = require("../../api/dealer.js");

var _firmwarepair = require("../../api/firmwarepair.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isDisable: false,
      isFlag: true,
      flags: false,
      inflag: false,
      outflag: false,
      form: {
        batchNo: "",
        deployVersion: "",
        endTime: "",
        equipmentModel: "",
        equipmentNumber: "",
        equipmentPurpose: "",
        firmwareVersion: "",
        flag: "",
        healthyState: "",
        pageNo: 1,
        pageSize: 10,
        startTime: "",
        stockDetailId: ''
      },
      options: [{
        value: "1",
        label: "入库"
      }, {
        value: "2",
        label: "出库"
      }],
      health: [{
        value: "0",
        label: "正常"
      }, {
        value: "1",
        label: "维修中"
      }, {
        value: "2",
        label: "报废"
      }],
      dealer: {
        name: "",
        telephone: "",
        address: ""
      },
      option: [],
      option5: [],
      option2: [],
      tableData: [],
      //入库
      dialogFormsVisible: false,
      loading: false,
      isRepeat: false,
      formLabelWidth: "120px",
      lead: {
        batchNo: "",
        equipmentModel: "",
        equipmentNumber: "",
        firmwareVersion: "",
        remark: ""
      },
      stockOut: {
        dealerId: "",
        equipmentNumber: "",
        equipmentPurpose: "",
        firmwareVersion: "",
        remark: ""
      },
      toRules: {
        equipmentModel: [{
          required: true,
          trigger: "blur"
        }],
        batchNo: [{
          required: true,
          trigger: "blur"
        }],
        firmwareVersion: [{
          required: true,
          trigger: "blur"
        }],
        equipmentNumber: [{
          required: true,
          trigger: "blur"
        }]
      },
      stockRules: {
        equipmentPurpose: [{
          required: true,
          trigger: "blur"
        }],
        dealerId: [{
          required: true,
          trigger: "blur"
        }],
        equipmentNumber: [{
          required: true,
          trigger: "blur"
        }],
        firmwareVersion: [{
          required: true,
          trigger: "blur"
        }]
      },
      //出库
      dialogFormVisible: false,
      //撤销
      multipleSelection: [],
      equipmentNumber: "",
      //批入
      dialogVisible: false,
      into: {
        equipmentModel: "",
        remark: ""
      },
      //批出
      dialogsVisible: false,
      outs: {
        dealerId: "",
        equipmentPurpose: "",
        remark: ""
      },
      purpostData: [],
      dealerData: [],
      selectId: "",
      user: "",
      telephone: "",
      address: "",
      //健康状态
      dialogVisibles: false,
      dialogEquipmentPurpo: false,
      healthData: {
        equipmentNumber: "",
        healthyState: ""
      },
      equipmentPurpoData: {
        equipmentNumber: "",
        equipmentPurpose: ""
      },
      total: 0,
      currentPage: 1,
      showFlag: true
    };
  },
  created: function created() {
    var _this = this;

    this.getList();
    (0, _firmwarepair.getLanList)({
      name: "EQUIPMENT_MODEL",
      value: ""
    }).then(function (res) {
      _this.option = res.dictionaryVOList;
    });
    (0, _firmwarepair.getLanList)({
      name: "EQUIPMENT_PURPOSE",
      value: ""
    }).then(function (res) {
      _this.purpostData = res.dictionaryVOList;
    });
    (0, _dealer.getDealerAll)().then(function (res) {
      _this.option2 = res.voList;
    });
    var req = new XMLHttpRequest();
    req.open('GET', document.location, false);
    req.send(null);
    var userType = req.getResponseHeader('Domain') ? req.getResponseHeader('Domain') : '';

    if (userType == 'com') {
      this.showFlag = false;
    } else {
      this.showFlag = true;
    }
  },
  methods: {
    getList: function getList() {
      var _this2 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _equipment.getAllinfo)(_this2.form).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.loading = false;
          _this2.isRepeat = false;
        });
      }, 500);
    },
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    stateFormat: function stateFormat(row) {
      if (row.flag == "1") {
        return "入库";
      } else if (row.flag == "2") {
        return "出库";
      }
    },
    state: function state(row) {
      if (row.state == "Y") {
        return "已激活";
      } else {
        return "未激活";
      }
    },
    //查询
    search: function search() {
      var _this3 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _equipment.getAllinfo)({
          batchNo: _this3.form.batchNo,
          deployVersion: _this3.form.deployVersion,
          endTime: _this3.form.endTime,
          equipmentModel: _this3.form.equipmentModel,
          equipmentNumber: _this3.form.equipmentNumber,
          equipmentPurpose: _this3.form.equipmentPurpose,
          firmwareVersion: _this3.form.firmwareVersion,
          flag: _this3.form.flag,
          healthyState: _this3.form.healthyState,
          pageNo: 1,
          pageSize: 10,
          startTime: _this3.form.startTime,
          stockDetailId: _this3.form.stockDetailId
        }).then(function (res) {
          _this3.tableData = res.voList;
          _this3.total = res.total;
          _this3.currentPage = 1;
          _this3.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.equipmentModel = "";
      this.form.equipmentNumber = "";
      this.form.batchNo = "";
      this.form.firmwareVersion = "";
      this.form.deployVersion = "";
      this.form.flag = "";
      this.form.healthyState = "";
      this.form.startTime = "";
      this.form.endTime = "";
      this.form.equipmentPurpose = "";
      this.form.stockDetailId = "";
      this.getList();
    },
    //弹框关闭
    close: function close() {
      this.dialogFormsVisible = false;
      this.dialogFormVisible = false;
      this.$refs.lead.resetFields();
      this.lead.remark = "";
    },
    intoclose: function intoclose() {
      this.dialogVisible = false;
      this.into.equipmentModel = "";
      this.into.remark = "";
    },
    stockclose: function stockclose() {
      this.dialogFormVisible = false;
      this.$refs.stockOut.resetFields();
      this.dealer.name = "";
      this.dealer.telephone = "";
      this.dealer.address = "";
      this.stockOut.remark = "";
    },
    outclose: function outclose() {
      this.dialogsVisible = false;
      this.outs.equipmentPurpose = "";
      this.outs.dealerId = "";
      this.outs.remark = "";
      this.user = "";
      this.telephone = "";
      this.address = "";
    },
    headlthyClose: function headlthyClose() {
      this.dialogVisibles = false;
      this.healthData.healthyState = "";
      dialogEquipmentPurpo;
    },
    equipmentPurpoClose: function equipmentPurpoClose() {
      this.dialogEquipmentPurpo = false;
      this.equipmentPurpoData.equipmentPurpo = "";
    },
    //入库
    tolead: function tolead() {
      this.dialogFormsVisible = true;
      this.isDisable = false;
    },
    save: function save() {
      var _this4 = this;

      this.$refs.lead.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this4.isRepeat = true;
          _this4.isDisable = true;
          (0, _equipment.toLead)(_this4.lead).then(function (res) {
            if (res.success) {
              _this4.msgTip("success", "添加成功");

              _this4.dialogFormsVisible = false;

              _this4.empty();
            } else {
              _this4.msgTip("error", res.errorMsg);

              _this4.isRepeat = false;
              _this4.isDisable = false;
            }
          });
        } else {
          _this4.msgTip("warning", "请填写完整");

          return false;
        }
      });
    },
    //出库
    stock: function stock() {
      var _this5 = this;

      this.dialogFormVisible = true;
      this.isDisable = false;
      (0, _firmwarepair.getLanList)({
        name: "EQUIPMENT_PURPOSE",
        value: ""
      }).then(function (res) {
        _this5.purpostData = res.dictionaryVOList;
      });
    },
    keep: function keep() {
      var _this6 = this;

      this.$refs.stockOut.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this6.isRepeat = true;
          _this6.isDisable = true;
          (0, _equipment.stock)(_this6.stockOut).then(function (res) {
            if (res.success) {
              _this6.msgTip("success", "添加成功");

              _this6.dialogFormVisible = false;

              _this6.empty();
            } else {
              _this6.msgTip("error", res.errorMsg);

              _this6.isRepeat = false;
              _this6.isDisable = false;
            }
          });
        } else {
          _this6.msgTip("warning", "请填写完整");

          return false;
        }
      });
    },
    repeals: function repeals() {
      this.flags = true; // this.flags = !this.flags;

      this.inflag = false;
      this.outflag = false;
    },
    //撤销
    repeal: function repeal() {
      var _this7 = this;

      if (this.currentrow == null) {
        this.msgTip("error", "请选择需要撤销的记录");
      } else {
        this.$confirm("您是否要撤销当前选中的记录？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this7.isRepeat = true;
          (0, _equipment.backout)(_this7.equipmentNumber).then(function (res) {
            if (res.success) {
              _this7.$message({
                type: "success",
                message: "撤销成功",
                center: true
              });

              _this7.empty();
            } else {
              _this7.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });

              _this7.isRepeat = false;
            }
          });
        });
      }
    },
    repealModel: function repealModel() {
      (0, _equipment.undoModeldownload)().then(function (res) {
        var data = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "stockBatchUndoModel.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(data);
      });
    },
    //批量撤销
    repealAll: function repealAll() {
      var _this8 = this;

      var fileData = this.$refs.repealfile.files[0];
      var formFile = new FormData(); //加入文件对象

      formFile.append("file", fileData);
      var extension = fileData.name.split(".")[1] === "xlsx";

      if (!extension) {
        this.msgTip("error", "上传模板只能是xlsx格式!"); // return;
      } else {
        this.isRepeat = true;
        (0, _equipment.undoAlldownload)(formFile).then(function (res) {
          if (res.success) {
            _this8.flags = false;

            _this8.getList();

            _this8.msgTip("success", "批量撤销成功");

            _this8.$refs.repealFile.value = [];
          } else {
            _this8.flags = false;

            _this8.$message({
              message: res.errorMsg,
              type: 'error',
              duration: 12000
            });

            _this8.$refs.repealFile.value = [];
            _this8.isRepeat = false;
          }
        });
      }
    },
    //批入模板
    inmodel: function inmodel() {
      (0, _equipment.inModeldownload)().then(function (res) {
        var data = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "stockBatchInModel.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(data);
      });
    },
    inmodels: function inmodels() {
      this.inflag = !this.inflag;
      this.flags = false;
      this.outflag = false;
    },
    //批入
    intos: function intos() {
      this.dialogVisible = true;
    },
    submitUpload: function submitUpload() {
      var _this9 = this;

      var fileData = this.$refs.uploadFile.files[0];
      var name = fileData.name.split('.')[fileData.name.split('.').length - 1];
      var formFile = new FormData(); //加入文件对象

      formFile.append("file", fileData);
      formFile.append("remark", this.into.remark);

      if (this.into.equipmentModel === "") {
        this.msgTip("error", "请选择设备型号！");
      } else if (name !== 'xlsx') {
        this.msgTip("error", "文件格式仅支持xlsx！");
      } else {
        this.isRepeat = true;
        (0, _equipment.toLeadall)(this.into.equipmentModel, formFile).then(function (res) {
          if (res.success) {
            _this9.msgTip("success", "导入成功");

            _this9.$refs.uploadFile.value = [];
            _this9.dialogVisible = false;

            _this9.empty();
          } else {
            // this.msgTip("error", res.errorMsg);
            _this9.$message({
              message: res.errorMsg,
              type: 'error',
              duration: 12000
            });

            _this9.$refs.uploadFile.value = [];
            _this9.isRepeat = false;
          }
        });
      }
    },
    outclick: function outclick() {
      this.outflag = !this.outflag;
      this.flags = false;
      this.inflag = false;
    },
    //批出模板
    outmodel: function outmodel() {
      (0, _equipment.outModeldownload)().then(function (res) {
        var data = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "stockBatchOutModel.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(data);
      });
    },
    //批出
    out: function out() {
      var _this10 = this;

      this.dialogsVisible = true;
      (0, _firmwarepair.getLanList)({
        name: "EQUIPMENT_PURPOSE",
        value: ""
      }).then(function (res) {
        _this10.purpostData = res.dictionaryVOList;
      });
      (0, _dealer.getDealerAll)().then(function (res) {
        _this10.dealerData = res.voList;
      });
    },
    handleFirst: function handleFirst(val) {
      var _this11 = this;

      (0, _dealer.getDealerAlone)(val).then(function (res) {
        _this11.user = res.voT.contact;
        _this11.telephone = res.voT.phone;
        _this11.address = res.voT.address;
      });
    },
    outUpload: function outUpload() {
      var _this12 = this;

      var fileData = this.$refs.outFile.files[0];
      var formFile = new FormData(); //加入文件对象

      var name = fileData.name.split('.')[fileData.name.split('.').length - 1];
      formFile.append("file", fileData);
      formFile.append("dealerId", this.outs.dealerId);
      formFile.append("equipmentPurpose", this.outs.equipmentPurpose);
      formFile.append("remark", this.outs.remark);
      this.isRepeat = true;

      if (name !== 'xlsx') {
        this.msgTip("error", "文件格式仅支持xlsx！");
      } else {
        (0, _equipment.stockAll)(formFile).then(function (res) {
          if (res.success) {
            _this12.msgTip("success", "批出成功");

            _this12.$refs.outFile.value = [];
            _this12.dialogsVisible = false;

            _this12.empty();
          } else {
            _this12.$message({
              message: res.errorMsg,
              type: 'error',
              duration: 12000
            });

            _this12.$refs.outFile.value = [];
            _this12.isRepeat = false;
          }
        });
      }
    },
    //导出
    derive: function derive() {
      (0, _equipment.stockExport)(this.form).then(function (res) {
        var data = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "stock.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(data);
      });
    },
    //健康状态
    headlthy: function headlthy() {
      if (this.currentrow == null) {
        this.msgTip("error", "请选择需要修改健康状态的记录！");
      } else {
        if (this.currentrow.flag == 2) {
          this.msgTip("error", "已出库的设备不允许修改健康状态！");
        } else {
          this.dialogVisibles = true;
          this.isDisable = false;
          this.healthData.equipmentNumber = this.currentrow.equipmentNumber; // console.log(this.currentrow.equipmentNumber, "0000");
        }
      }
    },
    headlthySave: function headlthySave() {
      var _this13 = this;

      if (this.healthData.healthyState == "") {
        this.msgTip("error", "请选择设备型号");
      } else {
        this.isRepeat = true;
        this.isDisable = true;
        (0, _equipment.headlthystate)(this.healthData).then(function (res) {
          if (res.success) {
            _this13.msgTip("success", "修改成功");

            _this13.dialogVisibles = false; // 获取当前日期时间

            (0, _equipment.dataManipulation)(_this13.tableData, _this13.rowData, _this13.healthData.healthyState, "", getDate());
          } else {
            _this13.msgTip("error", res.errorMsg);

            _this13.isRepeat = false;
            _this13.isDisable = false;
          }
        });
      }
    },
    //设备用途
    equipmentPurpo: function equipmentPurpo() {
      var _this14 = this;

      if (this.currentrow == null) {
        this.msgTip("error", "请选择一条已出库的数据！");
      } else {
        if (this.currentrow.flag != 2) {
          this.msgTip("error", "未出库的设备不允许修改设备用途！");
        } else {
          (0, _firmwarepair.getLanList)({
            name: "EQUIPMENT_PURPOSE",
            value: ""
          }).then(function (res) {
            _this14.purpostData = res.dictionaryVOList;
          });
          this.dialogEquipmentPurpo = true;
          this.equipmentPurpoData.equipmentNumber = this.currentrow.equipmentNumber;
          this.equipmentPurpoData.equipmentPurpose = this.currentrow.equipmentPurpose;
        }
      }
    },
    equipmentPurpoSave: function equipmentPurpoSave() {
      var _this15 = this;

      if (this.equipmentPurpoData.equipmentPurpose == "") {
        this.msgTip("error", "请选择设备用途");
      } else {
        this.isRepeat = true;

        if (this.equipmentPurpoData.equipmentPurpose == "测试设备") {
          this.equipmentPurpoData.equipmentPurpose = "0";
        }

        if (this.equipmentPurpoData.equipmentPurpose == "售卖设备") {
          this.equipmentPurpoData.equipmentPurpose = "1";
        }

        (0, _equipment.equipmentPurpostate)(this.equipmentPurpoData).then(function (res) {
          if (res.success) {
            _this15.msgTip("success", "修改成功");

            _this15.dialogEquipmentPurpo = false; // 获取当前日期时间
            // dataManipulation(
            //   this.tableData,
            //   this.rowData,
            //   "",
            //   this.equipmentPurpoData.equipmentPurpose,
            //   getDate()
            // );

            _this15.getList();
          } else {
            _this15.msgTip("error", res.errorMsg);

            _this15.isRepeat = false;
          }
        });
      }
    },
    handleChangeOption: function handleChangeOption(value) {
      var _this16 = this;

      (0, _dealer.getDealerAlone)(value).then(function (res) {
        _this16.dealer.name = res.voT.contact;
        _this16.dealer.telephone = res.voT.phone;
        _this16.dealer.address = res.voT.address;
      });
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(val) {
      this.rowData = val;
      this.currentrow = val;
      this.equipmentNumber = this.currentrow.equipmentNumber;
    },
    //信息提示框
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};
/**
 * 获取当前时间.
 */

exports.default = _default;

function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)

  var minutes = date.getMinutes(); //获取当前分钟数(0-59)

  var seconds = date.getSeconds();

  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }

  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }

  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}