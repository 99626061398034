"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueQuillEditor = require("vue-quill-editor");

var _equipmentlog = require("../../api/equipmentlog.js");

var _q = require("q");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    quillEditor: _vueQuillEditor.quillEditor
  },
  data: function data() {
    return {
      isDisable: false,
      isFlag: true,
      content: "",
      editorOption: {},
      type: "",
      currentRow: "",
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      isDownload: "",
      flag: "",
      loading: false,
      isRepeat: false,
      delParam: {
        id: "",
        logName: "",
        flag: "",
        remark: ""
      },
      param: {
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        equipmentNumber: "",
        firmwareVersion: "",
        flag: "",
        isDownload: "",
        startDate: "",
        endDate: "",
        type: "",
        logName: ""
      },
      options: [{
        type: "",
        label: "所有"
      }, {
        type: "1",
        label: "普通日志"
      }, {
        type: "2",
        label: "错误日志"
      }],
      downloads: [{
        isDownload: "",
        label: "所有"
      }, {
        isDownload: "0",
        label: "未下载"
      }, {
        isDownload: "1",
        label: "已下载"
      }],
      flags: [{
        flag: "",
        label: "所有"
      }, {
        flag: "0",
        label: "未查看"
      }, {
        flag: "1",
        label: "已查看"
      }, {
        flag: "2",
        label: "已审阅"
      }],
      tableData: [],
      total: 0,
      currentPage: 1
    };
  },
  //刷新
  created: function created() {
    this.getList();
  },
  methods: {
    //点击获取当前点击行的数据
    getRow: function getRow(row) {
      this.currentRow = row;
      this.delParam.id = row.id;
      this.delParam.logName = row.logName;
      this.delParam.remark = row.remark;
      this.isDownload = row.isDownload;
      this.flag = row.flag;
      this.type = row.type;
    },
    getList: function getList() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        (0, _equipmentlog.searchEquipmentLogList)(_this.param).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total; //把原来选中行的数据清除

          _this.currentRow = "";
          _this.isDownload = "";
          _this.flag = "";
          _this.delParam.id = "";
          _this.delParam.logName = "";
          _this.delParam.remark = "";
          _this.type = "";
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 100);
    },
    //判断日志类型
    typeFormatter: function typeFormatter(row, column) {
      if (row.type == "1") {
        return "普通日志";
      } else if (row.type == "2") {
        return "错误日志";
      }
    },
    //判断下载状态
    isDownloadFormatter: function isDownloadFormatter(row, column) {
      if (row.isDownload == "0") {
        return "未下载";
      } else if (row.isDownload == "1") {
        return "已下载";
      }
    },
    //判断标识
    flagFormatter: function flagFormatter(row, column) {
      if (row.flag == "0") {
        return "未查看";
      } else if (row.flag == "1") {
        return "已查看";
      } else if (row.flag == "2") {
        return "已审阅";
      }
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //当前页码
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.param.pageNo = currentPage;
      this.getList();
    },
    //每页显示的条数
    handleSizeChange: function handleSizeChange(pageSize) {
      this.param.pageSize = pageSize;
      this.getList();
    },
    //根据参数进行查询
    search: function search() {
      var _this2 = this;

      //参数查询数据
      this.loading = true;
      setTimeout(function () {
        (0, _equipmentlog.searchEquipmentLogList)({
          pageNo: 1,
          pageSize: 10,
          sortField: "",
          sortRules: "",
          equipmentNumber: _this2.param.equipmentNumber,
          firmwareVersion: _this2.param.firmwareVersion,
          flag: _this2.param.flag,
          logName: _this2.param.logName,
          isDownload: _this2.param.isDownload,
          startDate: _this2.param.startDate,
          endDate: _this2.param.endDate,
          type: _this2.param.type
        }).then(function (res) {
          //第一页开始显示
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.currentPage = 1;
          _this2.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.param.equipmentNumber = "";
      this.param.firmwareVersion = "";
      this.param.flag = "";
      this.param.isDownload = "";
      this.param.startDate = "";
      this.param.endDate = "";
      this.param.type = "";
      this.param.logName = "";
      this.getList();
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    //删除
    delet: function delet() {
      var _this3 = this;

      if (this.currentRow == "" || this.currentRow == null) {
        this.msgTip("error", "请选择需要删除的记录");
      } else {
        this.$confirm("您是否要删除当前选中的记录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this3.isRepeat = true;
          (0, _equipmentlog.deleteEquipmentLog)(_this3.delParam).then(function (res) {
            if (res.success) {
              _this3.$message({
                type: "success",
                message: "删除成功",
                center: true
              });

              _this3.param.pageNo = 1;

              _this3.getList();
            } else {
              //删除失败
              _this3.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });

              _this3.isRepeat = false;
            }
          });
        });
      }
    },
    //日志下载点击事件
    download: function download() {
      var _this4 = this;

      if (this.currentRow == "" || this.currentRow == null) {
        this.msgTip("error", "请选择需要下载的记录");
      } else {
        if (this.isFlag) {
          this.isFlag = false;
          (0, _equipmentlog.download)(this.delParam).then(function (res) {
            if (res.success) {
              window.location.href = res.url;
              _this4.delParam.flag = "true"; //修改下载状态

              (0, _equipmentlog.updateIsDownload)(_this4.delParam).then(function (res) {
                if (res.success) {
                  //刷新
                  //this.getList();
                  _this4.tableData.map(function (item) {
                    if (item.id == _this4.currentRow.id) {
                      item.isDownload = 1;
                      _this4.isDownload = 1;
                    }
                  });

                  _this4.isFlag = true;
                } else {
                  _this4.$message({
                    type: "warning",
                    message: res.errorMsg,
                    center: true
                  });

                  _this4.isFlag = true;
                }
              });
            }
          });
        }
      }
    },
    //备注点击按钮
    remarks: function remarks() {
      if (this.currentRow == "" || this.currentRow == null) {
        this.msgTip("error", "请选择需要添加备注的记录");
      } else {
        if (this.isDownload == "0") {
          this.$message({
            type: "warning",
            message: "未下载的记录不允许添加备注",
            center: true
          });
        } else {
          if (this.currentRow.flag == 2) {
            this.msgTip("error", "已审阅的不能在备注");
            return;
          } //显示备注框


          this.dialogVisible1 = true;
          this.isDisable = false;
          this.content = this.delParam.remark;
        }
      }
    },
    //审阅点击按钮
    review: function review() {
      if (this.currentRow == "" || this.currentRow == null) {
        this.msgTip("error", "请选择需要审阅的记录");
      } else {
        if (this.flag != "1") {
          this.$message({
            type: "warning",
            message: "仅允许审阅已查看的记录",
            center: true
          });
        } else {
          //显示备注框
          this.dialogVisible2 = true;
          this.isDisable = false;
        }
      }
    },
    //备注信息查看
    tickets: function tickets() {
      this.dialogVisible3 = true;
    },
    //审阅通过按钮
    trial: function trial() {
      var _this5 = this;

      this.isRepeat = true;
      this.isDisable = true;
      (0, _equipmentlog.passed)(this.delParam).then(function (res) {
        if (res.success) {
          _this5.$message({
            type: "success",
            message: "请求成功",
            center: true
          }); //隐藏富文本框


          _this5.dialogVisible2 = false; //刷新

          _this5.tableData.map(function (item) {
            if (item.id == _this5.currentRow.id) {
              item.flag = 2;
              _this5.flag = 2;
            }
          });
        } else {
          //请求失败
          _this5.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });

          _this5.isRepeat = false;
          _this5.isDisable = false;
        }
      });
    },
    //保存备注按钮
    save: function save() {
      var _this6 = this;

      if (this.content == null || this.content == "") {
        this.$message({
          type: "warning",
          message: "备注信息不能为空",
          center: true
        });
        return;
      }

      this.delParam.remark = this.content;
      this.isRepeat = true;
      this.isDisable = true;
      (0, _equipmentlog.addRemark)(this.delParam).then(function (res) {
        if (res.success) {
          _this6.$message({
            type: "success",
            message: "请求成功",
            center: true
          }); //this.getList();
          //隐藏富文本框


          _this6.dialogVisible1 = false; //刷新

          _this6.tableData.map(function (item) {
            if (item.id === _this6.currentRow.id) {
              item.flag = "1";
              _this6.flag = 1;
              item.remark = _this6.content;
            }
          });
        } else {
          //请求失败
          _this6.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });

          _this6.isRepeat = false;
          _this6.isDisable = false;
        }
      });
    },
    // 失去焦点事件
    onEditorFocus: function onEditorFocus(editor) {
      editor.enable(false);
    }
  }
};
exports.default = _default;