"use strict";

var _interopRequireDefault = require("/home/enotefront/app/snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeFirware = changeFirware;
exports.checkValidity = checkValidity;
exports.dataManipulation = dataManipulation;
exports.deleteFirware = deleteFirware;
exports.finishTest = finishTest;
exports.firwareUpload = firwareUpload;
exports.getChildDetailed = getChildDetailed;
exports.getDetailed = getDetailed;
exports.getFirware = getFirware;
exports.getFirwarechildren = getFirwarechildren;
exports.getTest = getTest;
exports.getTestlists = getTestlists;
exports.getUpload = getUpload;
exports.searchEquipment = searchEquipment;
exports.stride = stride;
exports.testDelete = testDelete;

var _request = _interopRequireDefault(require("@/utils/request"));

//查询所有经销商信息
function getFirware(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //查询固件版本子类信息


function getFirwarechildren(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/line/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //上传


function firwareUpload(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/upload',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //删除


function deleteFirware(id) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/delete/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
} //修改固件设备型号关系


function changeFirware(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/equipment/update',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //查询固件版本详细信息


function getDetailed(id) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/query/detail/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
} //查询子固件版本详细信息


function getChildDetailed(id) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/line/query/detail/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
} //校验合法性


function checkValidity(password, data) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/upload/check/' + password,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token'),
      "Content-Type": "multipart/form-data"
    },
    data: data,
    timeout: 600000
  });
} //上传


function getUpload(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/upload',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 3600000
  });
} //根据固件版本号查询设备型号


function searchEquipment(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/equipment/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //审核测试


function getTest(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/task/audit/test',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //审核测试删除


function testDelete(equipmentNumber) {
  return (0, _request.default)({
    url: 'system/business/firmware/task/delete/' + equipmentNumber,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
} //根据版本号查询所有库存信息


function getTestlists(data) {
  return (0, _request.default)({
    url: 'system/business/stock/version/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //跳过


function stride(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/stride',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //审核


function finishTest(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/audit',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
/**
 * 前端刷新公共方法
 *
 */


function dataManipulation(tableData, rowData, status, auditFlag, auditInfo, isStride, auditTime) {
  // console.log(isStride)
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环 判断
    if (element.id == rowData.id) {
      if (auditInfo != null && auditInfo != "") {
        element.auditInfo = auditInfo;
      }

      if (status != null && status != "") {
        element.status = status;
      }

      if (auditFlag != null && auditFlag != "") {
        element.auditFlag = auditFlag;
      }

      element.isStride = "0";

      if (isStride != null && isStride != "") {
        if (isStride == 0 || isStride == "0") {
          element.isStride = "0";
        }

        if (isStride == 1 || isStride == "1") {
          element.isStride = "1";
        }
      }

      if (auditTime != null && auditTime != "") {
        element.auditUser = window.localStorage.getItem("username");
        element.auditTime = auditTime;
      }
    }
  });
  return tableData;
}