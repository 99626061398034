"use strict";

var _interopRequireDefault = require("/home/enotefront/app/snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.number.constructor");

var _login = require("../../api/login.js");

var _countryCode = _interopRequireDefault(require("../../countryCode.js"));

var _q = require("q");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isDisable: false,
      isFlag: true,
      value: "",
      options: [{
        value: "Y",
        label: "正常"
      }, {
        value: "N",
        label: "冻结"
      }],
      fileServers: [{
        value: "0",
        label: "中国"
      }, {
        value: "1",
        label: "亚马逊（日本）"
      }, {
        value: "2",
        label: "亚马逊（美国）"
      }, {
        value: "3",
        label: "亚马逊（欧洲）"
      }],
      sex: "",
      birthday: "",
      personalSign: "",
      hobby: "",
      education: "",
      job: "",
      address: "",
      totalCapacity: "",
      tableData: [],
      dialogVisibles: false,
      loading: false,
      isRepeat: false,
      formLabelWidth: "100px",
      form: {
        createTimeEnd: "",
        createTimeStart: "",
        email: "",
        isNormal: "",
        pageNo: 1,
        pageSize: 10,
        telephone: "",
        userName: "",
        fileServer: ''
      },
      phoneFlag: true,
      emailFlag: true,
      multipleSelection: [],
      isNormal: "",
      //修改手机号
      dialogFormVisible: false,
      oldphone: "",
      newphone: "",
      countryCode: "",
      boll: false,
      vCode: "86",
      cities: _countryCode.default,
      newcountry: "86",
      userid: "",
      //修改邮箱
      dialogFormsVisible: false,
      oldemail: "",
      newemail: "",
      total: 0,
      currentPage: 1
    };
  },
  created: function created() {
    this.getList();
  },
  mounted: function mounted() {},
  methods: {
    getList: function getList() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        (0, _login.getUsercontrol)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;

      if (val == "" || val == null) {
        document.all["changePhone"].style.color = "#000000";
        document.all["changeEmail"].style.color = "#000000";
        this.PhoneFlag = true;
        this.emailFlag = true;
      } else {
        if (val[0].telephone == null) {
          this.PhoneFlag = false;
          document.all["changePhone"].style.color = "#DCDCDC";
        } else {
          this.PhoneFlag = true;
          document.all["changePhone"].style.color = "#000000";
        }

        if (val.length > 0 && val[0].email == null) {
          this.emailFlag = false;
          document.all["changeEmail"].style.color = "#DCDCDC";
        } else {
          this.emailFlag = true;
          document.all["changeEmail"].style.color = "#000000";
        }
      }
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    // 查找有没有选中数据，没有则返回false
    findSelectData: function findSelectData() {
      if (!this.multipleSelection.length) {
        // 无选中的数据
        return false;
      } else {
        // 有选中的数据
        return true;
      }
    },
    //信息提示框
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //判断云服务器
    type: function type(row) {
      if (row.fileServer == "0") {
        return '中国';
      } else if (row.fileServer == "1") {
        return '亚马逊（日本）';
      } else if (row.fileServer == "2") {
        return '亚马逊（美国）';
      } else if (row.fileServer == "3") {
        return '亚马逊（欧洲）';
      }
    },
    //查询
    search: function search() {
      var _this2 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _login.getUsercontrol)({
          createTimeEnd: _this2.form.createTimeEnd,
          createTimeStart: _this2.form.createTimeStart,
          email: _this2.form.email,
          isNormal: _this2.form.isNormal,
          pageNo: 1,
          pageSize: 10,
          telephone: _this2.form.telephone,
          userName: _this2.form.userName,
          fileServer: _this2.form.fileServer
        }).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.currentPage = 1;
          _this2.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.createTimeEnd = "";
      this.form.createTimeStart = "";
      this.form.telephone = "";
      this.form.isNormal = "";
      this.form.userName = "";
      this.form.email = "";
      this.form.fileServer = "";
      this.getList();
    },
    //弹框关闭
    close: function close() {
      this.dialogFormVisible = false;
      this.dialogFormsVisible = false;
      this.newphone = "";
      this.newemail = "";
    },
    //修改手机号
    changePhone: function changePhone() {
      var _this3 = this;

      if (this.PhoneFlag || this.PhoneFlag == undefined) {
        if (this.multipleSelection.length < 1) {
          this.msgTip("error", "请选择一条数据");
          return;
        } else if (this.multipleSelection.length > 1) {
          this.msgTip("error", "只能选择一条数据");
          return;
        }

        this.multipleSelection.map(function (res) {
          _this3.multipleSelection.map(function (res) {
            _this3.countryCode = res.countryCode;
            _this3.oldphone = res.telephone;
            _this3.userid = res.userId;
          });

          _this3.dialogFormVisible = true;
          _this3.isDisable = false;
        });
      }
    },
    save: function save() {
      var _this4 = this;

      if (this.newphone == "") {
        this.$message({
          type: "warning",
          message: "请填写手机号",
          center: true
        });
      } else {
        if (this.newcountry == "86") {
          if (!/^1\d{10}$/.test(this.newphone)) {
            this.$message({
              type: "warning",
              message: "请填写正确的手机号",
              center: true
            });
          } else {
            this.isRepeat = true;
            this.isDisable = true;
            (0, _login.amendPhone)(this.userid, {
              countryCode: this.newcountry,
              telephone: this.newphone
            }).then(function (res) {
              if (res.success) {
                _this4.$message({
                  type: "success",
                  message: "修改成功",
                  center: true
                });

                _this4.dialogFormVisible = false;
                var tel = _this4.newphone;
                tel = "" + tel;
                var tel1 = tel.substr(0, 3) + "****" + tel.substr(7);
                (0, _login.dataManipulation)(_this4.tableData, _this4.multipleSelection, tel1, "", ""); //this.getList();
              } else {
                _this4.$message({
                  type: "error",
                  message: res.errorMsg,
                  center: true
                });

                _this4.isRepeat = false;
                _this4.isDisable = false;
              }
            });
          }
        } else {
          if (this.newphone.length < 8 || this.newphone.length > 15) {
            this.$message({
              type: "warning",
              message: "请输入8到15位的手机号",
              center: true
            });
            return;
          }

          if (/^[0-9]*$/.test(this.newphone)) {
            this.isRepeat = true;
            (0, _login.amendPhone)(this.userid, {
              countryCode: this.newcountry,
              telephone: this.newphone
            }).then(function (res) {
              if (res.success) {
                _this4.$message({
                  type: "success",
                  message: "修改成功",
                  center: true
                });

                _this4.dialogFormVisible = false;

                _this4.getList();
              } else {
                _this4.$message({
                  type: "error",
                  message: res.errorMsg,
                  center: true
                });

                _this4.isRepeat = false;
              }
            });
          }
        }
      }
    },
    //修改邮箱
    changeEmail: function changeEmail() {
      var _this5 = this;

      if (this.emailFlag) {
        if (!this.findSelectData()) {
          this.msgTip("error", "至少选择一条数据");
        } else {
          if (this.multipleSelection.length >= 2) {
            this.msgTip("error", "只能选择一条数据");
          } else {
            this.multipleSelection.map(function (res) {
              if (res.email != null) {
                _this5.oldemail = res.email;
                _this5.userid = res.userId;
              }
            });
            this.dialogFormsVisible = true;
            this.isDisable = false;
          }
        }
      }
    },
    keep: function keep() {
      var _this6 = this;

      if (this.newemail == "") {
        this.msgTip("warning", "请填写邮箱号");
      } else {
        if (/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(this.newemail)) {
          this.isRepeat = true;
          this.isDisable = true;
          (0, _login.amendEmail)(this.userid, {
            email: this.newemail
          }).then(function (res) {
            if (res.success) {
              _this6.$message({
                type: "success",
                message: "修改成功",
                center: true
              });

              _this6.dialogFormsVisible = false;
              var tel = _this6.newemail;
              tel = "" + tel;
              var ary = tel.split("");
              ary.splice(1, 2, "**");
              var tel1 = ary.join("");
              (0, _login.dataManipulation)(_this6.tableData, _this6.multipleSelection, "", tel1, ""); //this.getList();
            } else {
              _this6.$message({
                type: "error",
                message: res.errorMsg,
                center: true
              });

              _this6.isRepeat = false;
              _this6.isDisable = false;
            }
          });
        } else {
          this.msgTip("warning", "请填写正确邮箱号");
        }
      }
    },
    //冻结
    freeze: function freeze() {
      var _this7 = this;

      this.multipleSelection.map(function (res) {
        _this7.userid = res.userId;
        _this7.isNormal = res.isNormal;
      });

      if (!this.findSelectData()) {
        this.msgTip("error", "至少选择一条数据");
      } else {
        if (this.multipleSelection.length >= 2) {
          this.msgTip("error", "只能选择一条数据");
        } else {
          if (this.isNormal == "冻结") {
            this.msgTip("warning", "此条数据已经被冻结！");
          } else {
            if (this.isFlag) {
              this.isFlag = false;
              this.isRepeat = true;
              (0, _login.freezes)({
                flag: "N",
                userId: this.userid
              }).then(function (res) {
                if (res.success) {
                  _this7.$message({
                    type: "success",
                    message: "冻结成功",
                    center: true
                  });

                  (0, _login.dataManipulation)(_this7.tableData, _this7.multipleSelection, "", "", "冻结");
                  _this7.isFlag = true; //this.getList();
                } else {
                  _this7.$message({
                    type: "warning",
                    message: res.errorMsg,
                    center: true
                  });

                  _this7.isRepeat = false;
                  _this7.isFlag = true;
                }
              });
            }
          }
        }
      }
    },
    //解冻
    unfreeze: function unfreeze() {
      var _this8 = this;

      this.multipleSelection.map(function (res) {
        _this8.isNormal = res.isNormal;
        _this8.userid = res.userId;
      });

      if (!this.findSelectData()) {
        this.msgTip("error", "至少选择一条数据");
      } else {
        if (this.multipleSelection.length >= 2) {
          this.msgTip("error", "只能选择一条数据");
        } else {
          if (this.isNormal == "正常") {
            this.msgTip("warning", "请选择需要解冻的数据！");
          } else {
            if (this.isFlag) {
              this.isRepeat = true;
              this.isFlag = false;
              (0, _login.freezes)({
                flag: "Y",
                userId: this.userid
              }).then(function (res) {
                if (res.success) {
                  _this8.$message({
                    type: "success",
                    message: "解冻成功",
                    center: true
                  });

                  (0, _login.dataManipulation)(_this8.tableData, _this8.multipleSelection, "", "", "正常"); //this.getList();

                  _this8.isFlag = true;
                } else {
                  _this8.$message({
                    type: "warning",
                    message: res.errorMsg,
                    center: true
                  });

                  _this8.isRepeat = false;
                  _this8.isFlag = true;
                }
              });
            }
          }
        }
      }
    },
    //详情
    details: function details(v) {
      var _this9 = this;

      this.dialogVisibles = true;
      (0, _login.getIduser)(v.userId).then(function (res) {
        _this9.sex = res.sex;
        _this9.birthday = res.birthday;
        _this9.personalSign = res.personalSign;
        _this9.hobby = res.hobby;
        _this9.education = res.education;
        _this9.job = res.job;
        _this9.address = res.address;
      });
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
exports.default = _default;