"use strict";

var _interopRequireWildcard = require("/home/enotefront/app/snote-manage/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var Quill = _interopRequireWildcard(require("quill"));

var _apprepair = require("../../api/apprepair.js");

var _uploads = require("../../api/uploads.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isDisable: false,
      id: "",
      lanData: [],
      appNameData: [],
      formLabelWidth: "100px",
      loading: false,
      isRepeat: false,
      data: {
        appName: "",
        appVersion: "",
        fixPoint: "",
        lan: ""
      },
      updateRowData: {
        appName: "",
        appVersion: "",
        fixPoint: "",
        lan: ""
      },
      rulesadd: {
        lan: [{
          required: true,
          trigger: "change"
        }],
        appName: [{
          required: true,
          trigger: "change"
        }],
        appVersion: [{
          required: true,
          trigger: "change"
        }],
        fixPoint: [{
          required: true,
          trigger: "blur"
        }]
      },
      editorOption: {
        placeholder: "请输入文本信息...",
        theme: "snow",
        modules: {
          toolbar: [["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
          ["blockquote", "code-block"], //引用，代码块
          [{
            header: 1
          }, {
            header: 2
          }], // 标题，键值对的形式；1、2表示字体大小
          [{
            list: "ordered"
          }, {
            list: "bullet"
          }], //列表
          [{
            script: "sub"
          }, {
            script: "super"
          }], // 上下标
          [{
            indent: "-1"
          }, {
            indent: "+1"
          }], // 缩进
          [{
            direction: "rtl"
          }], // 文本方向
          [{
            size: ["small", false, "large", "huge"]
          }], // 字体大小
          [{
            header: [1, 2, 3, 4, 5, 6, false]
          }], //几级标题
          [{
            color: []
          }, {
            background: []
          }], // 字体颜色，字体背景颜色
          [{
            font: []
          }], //字体
          [{
            align: []
          }], //对齐方式
          ["image"] //清除字体样式 //['image','video']        //上传图片、上传视频
          ]
        }
      },
      addRange: [],
      Rangeflag: false
    };
  },
  mounted: function mounted() {
    // 为图片ICON绑定事件  getModule 为编辑器的内部属性
    this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", this.imgHandler);
  },
  methods: {
    getLan: function getLan() {
      var _this = this;

      (0, _apprepair.getLanList)({
        name: "lan"
      }).then(function (res) {
        _this.lanData = res.dictionaryVOList;
      });
    },
    //查询app名称列表
    getFirst: function getFirst() {
      var _this2 = this;

      (0, _apprepair.getAppNameList)().then(function (res) {
        _this2.data.appName = "";
        _this2.data.appVersion = "";
        _this2.appNameData = res.voList;
      });
    },
    getSecond: function getSecond() {
      var _this3 = this;

      (0, _apprepair.getAppVersionList)({
        appName: this.data.appName
      }).then(function (res) {
        _this3.appNameData = res.voList;
      });
    },
    handleChangeFirst: function handleChangeFirst(value) {
      this.data.appName = value;
    },
    imgHandler: function imgHandler(state) {
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();

      if (this.addRange != "" && this.addRange != null) {
        this.Rangeflag = true;
      }

      if (state) {
        var fileInput = document.getElementById("imgInput");
        fileInput.click(); // 加一个触发事件
      }
    },
    to_upload_img: function to_upload_img(formdata) {
      var _this4 = this;

      return new Promise(function (resolve, reject) {
        var fd = new FormData();
        fd.append("file", formdata.file);
        (0, _uploads.uploads)(fd).then(function (res) {
          if (_this4.Rangeflag) {
            _this4.addRange = _this4.$refs.myQuillEditor.quill.getSelection();

            _this4.$refs.myQuillEditor.quill.insertEmbed(_this4.addRange !== null ? _this4.addRange.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL


            _this4.Rangeflag = false;
          }
        });
      });
    },
    //提交
    submit: function submit() {
      var _this5 = this;

      this.$refs.data.validate(function (valid) {
        if (_this5.data.fixPoint == "" || _this5.data.fixPoint == null) {
          _this5.data.fixPoint = '<p><strong style="color: rgb(230, 0, 0);"><em>请输入修复点信息(不可为空)</em></strong></p>';
          return false;
        }

        if (valid) {
          _this5.isRepeat = true;
          _this5.isDisable = true;
          (0, _apprepair.addAppFixpoint)(_this5.data).then(function (res) {
            if (res.success) {
              _this5.$message({
                type: "success",
                message: "添加成功",
                center: true
              });

              _this5.$store.dispatch("tagsView/delView", _this5.$route).then(function () {
                _this5.$nextTick(function () {
                  _this5.$router.replace({
                    path: "/apply/apprepair"
                  });
                });
              });
            } else {
              _this5.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });

              _this5.isRepeat = false;
              _this5.isDisable = false;
            }
          });
        } else {
          _this5.$message({
            type: "warning",
            message: "请填写完整",
            center: true
          });
        }
      });
    }
  }
};
exports.default = _default;