"use strict";

var _interopRequireDefault = require("/home/enotefront/app/snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.changeList = changeList;
exports.deletaList = deletaList;
exports.getreferlist = getreferlist;
exports.updateList = updateList;

var _request = _interopRequireDefault(require("@/utils/request"));

function getreferlist(data) {
  return (0, _request.default)({
    url: 'system/base/reference',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

function addList(data) {
  return (0, _request.default)({
    url: 'system/base/reference/add',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //根据id查询系统参数详细信息


function changeList(id) {
  return (0, _request.default)({
    url: 'system/base/reference/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
} //更新系统参数详细信息接口


function updateList(id, data) {
  return (0, _request.default)({
    url: 'system/base/reference/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
} //根据id删除系统参数详细信息接口


function deletaList(id) {
  return (0, _request.default)({
    url: 'system/base/reference/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}