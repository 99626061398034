"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _equimentInformation = require("../../api/equimentInformation.js");

var _equimentBindRecord = require("../../api/equimentBindRecord.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isDisable: false,
      value: "",
      loading: false,
      tableData: [],
      bindRecordList: [],
      bindRecordDialog: false,
      equipmentNumber: "",
      form: {
        countryCode: "",
        email: "",
        equipmentNumber: "",
        pageNo: 1,
        pageSize: 10,
        telephone: "",
        firmwareVersion: '' // fileServer:''

      },
      currentPage: 1,
      total: 0,
      fileServers: [{
        value: "0",
        label: "中国"
      }, {
        value: "1",
        label: "亚马逊（日本）"
      }]
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        (0, _equimentInformation.getInfomationList)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.equipmentNumber = val.equipmentNumber;
    },
    //绑定记录关闭
    bindRecordClose: function bindRecordClose() {
      this.bindRecordDialog = false;
    },
    //绑定记录
    bindRecord: function bindRecord() {
      var _this2 = this;

      if (this.equipmentNumber != "") {
        (0, _equimentBindRecord.queryEquipmentBindRecord)({
          equipmentNumber: this.equipmentNumber,
          pageSize: 5000,
          pageNo: 1
        }).then(function (i) {
          if (i.success) {
            _this2.bindRecordList = i.voList;
            _this2.bindRecordDialog = true;
          } else {
            _this2.dialogsForm = false;

            _this2.$message({
              type: 'error',
              message: i.errorMsg,
              center: true
            });
          }
        });
      } else {
        this.$message({
          type: 'warning',
          message: '请选择至少一条数据',
          center: true
        });
      }
    },
    //查询
    search: function search() {
      var _this3 = this;

      this.loading = true;
      this.equipmentNumber = "";
      setTimeout(function () {
        (0, _equimentInformation.getInfomationList)({
          countryCode: "",
          email: _this3.form.email,
          equipmentNumber: _this3.form.equipmentNumber,
          pageNo: 1,
          pageSize: 10,
          telephone: _this3.form.telephone,
          fileServer: _this3.form.fileServer,
          firmwareVersion: _this3.form.firmwareVersion
        }).then(function (res) {
          _this3.tableData = res.voList;
          _this3.total = res.total;
          _this3.currentPage = 1;
          _this3.loading = false;
        });
      }, 500);
    },
    //判断云服务器
    type: function type(row) {
      if (row.fileServer == "0") {
        return '中国';
      } else if (row.fileServer == "1") {
        return '亚马逊（日本）';
      } else if (row.fileServer == "2") {
        return '亚马逊（美国）';
      } else if (row.fileServer == "3") {
        return '亚马逊（欧洲）';
      }
    },
    //判断操作类型
    values: function values(row) {
      if (row.type == "0") {
        return '绑定（常规）';
      } else if (row.type == "1") {
        return '解绑（常规）';
      } else if (row.type == "2") {
        return '解绑（迁出）';
      } else if (row.type == "3") {
        return '解绑（登录）';
      }
    },
    //清空
    empty: function empty() {
      this.form.equipmentNumber = "";
      this.form.telephone = "";
      this.form.email = "";
      this.form.firmwareVersion = "";
      this.form.fileServer = '';
      this.currentPage = 1;
      this.form.pageNo = 1;
      this.getList();
      this.equipmentNumber = "";
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
exports.default = _default;