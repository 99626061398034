"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/web.dom.iterable");

var _dealer = require("../../api/dealer.js");

var _dictionary = require("../../api/dictionary.js");

var _firmwarepair = require("../../api/firmwarepair.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isDisable: false,
      rules: {
        dealerType: [{
          required: true,
          trigger: "blur"
        }],
        dealerName: [{
          required: true,
          trigger: "blur"
        }],
        contact: [{
          required: true,
          trigger: "blur"
        }],
        phone: [{
          required: true,
          trigger: "blur"
        }],
        address: [{
          required: true,
          trigger: "blur"
        }],
        salesman: [{
          required: true,
          trigger: "blur"
        }],
        applicationTime: [{
          required: true,
          trigger: "blur"
        }]
      },
      options: [],
      tableData: [],
      loading: false,
      isRepeat: false,
      form: {
        address: "",
        applicationTime: "",
        contact: "",
        dealerName: "",
        dealerType: "",
        phone: "",
        remark: "",
        salesman: ""
      },
      tab: {
        applicationTimeEnd: "",
        applicationTimeStart: "",
        contact: "",
        dealerName: "",
        dealerType: "",
        pageNo: 1,
        pageSize: 10,
        phone: ""
      },
      dialogVisible: false,
      formLabelWidth: "100px",
      multipleSelection: [],
      total: 0,
      currentPage: 1,
      //修改
      dialogVisibles: false
    };
  },
  created: function created() {
    var _this = this;

    this.getList();
    (0, _firmwarepair.getLanList)({
      name: "DEALERS_TYPE",
      value: ""
    }).then(function (res) {
      _this.options = res.dictionaryVOList;
    });
  },
  methods: {
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getList: function getList() {
      var _this2 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _dealer.getDealer)(_this2.tab).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.loading = false;
          _this2.isRepeat = false;
        });
      }, 1000);
    },
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.tab.pageNo = currentPage;
      this.getList();
    },
    //查询
    search: function search() {
      var _this3 = this;

      this.loading = true;
      setTimeout(function () {
        (0, _dealer.getDealer)({
          applicationTimeEnd: _this3.tab.applicationTimeEnd,
          applicationTimeStart: _this3.tab.applicationTimeStart,
          contact: _this3.tab.contact,
          dealerName: _this3.tab.dealerName,
          dealerType: _this3.tab.dealerType,
          pageNo: 1,
          pageSize: 10,
          phone: _this3.tab.phone
        }).then(function (res) {
          _this3.tab.pageNo = 1;
          _this3.tableData = res.voList;
          _this3.total = res.total;
          _this3.currentPage = 1;
          _this3.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.tab.dealerName = "";
      this.tab.contact = "";
      this.tab.phone = "";
      this.tab.dealerType = "";
      this.tab.applicationTimeStart = "";
      this.tab.applicationTimeEnd = "";
      this.getList();
    },
    //弹框关闭
    close: function close() {
      this.dialogVisible = false;
      this.dialogVisibles = false;
      this.$refs.form.resetFields();
      this.form.remark = "";
    },
    //添加
    add: function add() {
      this.dialogVisible = true;
      this.form.phone = "";
      this.isDisable = false;
    },
    //保存
    save: function save() {
      var _this4 = this;

      if (this.form.phone == "" || this.form.phone == null) {
        this.msgTip("warning", "联系电话不能为空");
        return;
      }

      if (!/^[-\d]+$/.test(this.form.phone) || this.form.phone.length < 8) {
        this.msgTip("warning", "请填写正确的联系电话");
        return;
      }

      this.$refs.form.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this4.isRepeat = true;
          _this4.isDisable = true;
          (0, _dealer.addDealer)(_this4.form).then(function (res) {
            if (res.success) {
              _this4.msgTip("success", "添加成功");

              _this4.dialogVisible = false;

              _this4.empty();
            } else {
              _this4.isRepeat = false;

              _this4.msgTip("error", res.errorMsg);

              _this4.isDisable = false;
            }
          });
        } else {
          _this4.msgTip("warning", "请填写完整");

          return false;
        }
      });
    },
    // 查找有没有选中数据，没有则返回false
    findSelectData: function findSelectData() {
      if (!this.multipleSelection.length) {
        // 无选中的数据
        return false;
      } else {
        // 有选中的数据
        return true;
      }
    },
    //信息提示框
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //修改
    change: function change() {
      var _this5 = this;

      if (this.multipleSelection == null || this.multipleSelection == "") {
        this.msgTip("error", "请选择需要修改的记录");
      } else {
        this.dialogVisibles = true;
        this.isDisable = false;
        (0, _dealer.getDealerAlone)(this.multipleSelection.id).then(function (res) {
          _this5.form.dealerType = res.voT.dealerType;
          _this5.form.applicationTime = res.voT.applicationTime;
          _this5.form.contact = res.voT.contact;
          _this5.form.dealerName = res.voT.dealerName;
          _this5.form.phone = res.voT.phone;
          _this5.form.salesman = res.voT.salesman;
          _this5.form.address = res.voT.address;
          _this5.form.remark = res.voT.remark;
        });
      }
    },
    keep: function keep() {
      var _this6 = this;

      if (this.form.phone == "" || this.form.phone == null) {
        this.msgTip("warning", "联系电话不能为空");
        return;
      }

      if (!/^[-\d]+$/.test(this.form.phone) || this.form.phone.length < 8) {
        this.msgTip("warning", "请填写正确的联系电话");
        return;
      }

      var flag = true;
      var dealerName = this.form.dealerName;
      var id = this.multipleSelection.id;
      this.tableData.forEach(function (element) {
        if (dealerName == element.dealerName) {
          if (id != element.id) {
            _this6.msgTip("error", "经销商名称已存在");

            flag = false;
            return;
          }
        }
      });

      if (flag) {
        // this.isRepeat = true;
        // this.isDisable = true;
        this.$refs.form.validate(function (valid) {
          if (valid) {
            (0, _dealer.exchangeDealer)(_this6.multipleSelection.id, _this6.form).then(function (res) {
              if (res.success) {
                _this6.$message({
                  type: "success",
                  message: "操作成功",
                  center: true
                });

                _this6.dialogVisibles = false;
                (0, _dealer.dataManipulation)(_this6.tableData, _this6.multipleSelection, _this6.form.dealerType, _this6.form.dealerName, _this6.form.contact, _this6.form.phone, _this6.form.address, _this6.form.salesman, _this6.form.applicationTime, _this6.form.remark); //this.getList();
              } else {
                _this6.$message({
                  type: "warning",
                  message: res.errorMsg,
                  center: true
                }); // this.isRepeat = false;
                // this.isDisable = false;

              }
            });
          } else {
            _this6.msgTip("warning", "请填写完整"); // return false;

          }
        });
      }
    },
    //删除
    dele: function dele() {
      var _this7 = this;

      if (this.multipleSelection == null || this.multipleSelection == "") {
        this.msgTip("error", "请选择需要删除的记录");
      } else {
        this.$confirm("您是否要删除当前选中的记录？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this7.isRepeat = true;
          (0, _dealer.deleteDealer)(_this7.multipleSelection.id).then(function (res) {
            if (res.success) {
              _this7.$message({
                type: "success",
                message: "删除成功",
                center: true
              });

              _this7.getList();
            } else {
              _this7.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });

              _this7.isRepeat = false;
            }
          });
        });
      }
    },
    //模板下载
    download: function download() {
      (0, _dealer.templateDownload)().then(function (res) {
        var data = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "DealerTemplate.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(anchor.href);
      });
    },
    //导入
    submitUpload: function submitUpload() {
      var _this8 = this;

      var fileData = this.$refs.uploadFile.files[0];
      var formFile = new FormData(); //加入文件对象

      formFile.append("file", fileData);
      var extension = fileData.name.split(".")[1] === "xlsx";

      if (!extension) {
        this.msgTip("error", "上传模板只能是xlsx格式!");
        return;
      } else {
        (0, _dealer.toLead)(formFile).then(function (res) {
          if (res.success) {
            _this8.msgTip("success", "导入成功");

            _this8.$refs.uploadFile.value = [];

            _this8.getList();
          } else {
            _this8.msgTip("error", res.errorMsg);

            _this8.$refs.uploadFile.value = [];
          }
        });
      }
    },
    //导出
    derives: function derives() {
      (0, _dealer.derive)(this.tab).then(function (res) {
        var data = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var result = document.getElementById("result");
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "DealerRecharge.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(data);
      });
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
exports.default = _default;